import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStore } from 'react-admin';

import MUIFolderIcon from '@mui/icons-material/Folder';

import { File } from './index';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

import { STORE } from '../../../constants';

const sortByName = (a, b) => {
  const a_name = (a.meta.originalName || a.id).toLowerCase();
  const b_name = (b.meta.originalName || b.id).toLowerCase();
  return a_name < b_name ? -1 : a_name > b_name ? 1 : 0;
};

const Folder = ({ label, fileList, onUpdatePreviewUrl }) => {
  const [isOpen, setIsOpen] = useStore(STORE.ASSET_LIST_OPENED_PREFIX + label, false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItemButton sx={{ padding: '0 10px', '&:hover': { bgcolor: 'background.dark' }}} onClick={handleClick}>
        <ListItemIcon>
          <MUIFolderIcon />
        </ListItemIcon>
        <ListItemText primary={`${label} (${fileList.length})`} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" sx={{ padding: '0 20px'}}>
          {fileList.sort(sortByName).map((file, i) => (
            <File file={file} key={`file-${i}`} onUpdatePreviewUrl={onUpdatePreviewUrl} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

Folder.propTypes = {
  children: PropTypes.node,
  fileList: PropTypes.array,
};

Folder.defaultProps = {
  fileList: [],
  onUpdatePreviewUrl: () => {}
};

export default Folder;
