import React from 'react';
import {
  Create,
  FileInput,
  FileField,
  FormTab,
  TabbedForm,
  useNotify,
  useRedirect,
  TextInput,
  RadioButtonGroupInput,
} from 'react-admin';

const LANGUAGES = [
  { id: 'de-DE', name: 'German' },
  { id: 'sv-SE', name: 'Swedish' },
];

const CreateTranscription = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onMutate = () => {
    notify(`resources.transcriptions.notifications.uploadStarted`);
  };

  const onSuccess = (data) => {
    if (data.id) {
      notify(`resources.transcriptions.notifications.transcriptionInProgress`);
    } else {
      notify(`resources.transcriptions.notifications.uploadCompleted`);
    }
    redirect('list', 'transcriptions');
  };

  return (
    <div>
      <Create mutationOptions={{ onMutate, onSuccess }} {...props}>
        <TabbedForm>
          <FormTab label="Upload audio file">
            <TextInput label="ISBN" required name="isbn" source="isbn" />
            <RadioButtonGroupInput required name="language" source="language" choices={LANGUAGES} />
            <FileInput
              multiple
              label="Upload audio files to be transcribed to ISBN. You can also add other files. They will be uploaded to Source files."
              name="files"
              source="files"
              sx={{
                '& .RaFileInput-dropZone': {
                  backgroundColor: 'backdrops.blue',
                },
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
          <FormTab label="Load from Source Files">
            <TextInput
              multiline
              name="isbns"
              label="ISBNs from Source Files"
              helperText="Write multiple isbn numbers separated with space. To take an 'audio isbn' and transcribing it to a 'book isbn' write: <audio isbn>:<book isbn>"
              source="isbns"
              inputProps={{ maxRows: 10 }}
              sx={{
                width: 270,
              }}
            />
            <RadioButtonGroupInput required source="language" choices={LANGUAGES} />
          </FormTab>
        </TabbedForm>
      </Create>
    </div>
  );
};

export default CreateTranscription;
