import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useNotify } from 'react-admin';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import AssignmentIcon from '@mui/icons-material/Assignment';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import SplitIcon from '@mui/icons-material/Code';
import ExcludeIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import SilenceIcon from '@mui/icons-material/MicOff';
import OffsetIcon from '@mui/icons-material/SyncAlt';
import CropIcon from '@mui/icons-material/Crop';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

import { extractAudioCutDetailsFromCommand } from '../../utils/text-extraction';
import Api from '../../dataProvider/index';
import slacker from '../../dataProvider/slacker';

import BookUploadButton from '../../components/fields/BookUploadButton';
// import EpubDownloadButton from '../../components/fields/EpubDownloadButton';

import AudioCuttingDialog from '../common/dialogs/AudioCuttingDialog';
import PasteDialog from '../common/dialogs/PasteDialog';
import ProgressDialog from '../common/dialogs/ProgressDialog';

import IconButton from '../common/IconButton';
import IconMultiButton from '../common/IconMultiButton';

import { MODES } from '../../constants';

const PREFIX = 'Toolbar';

const cls = {
  root: `${PREFIX}-root`,
  exportButtonContainer: `${PREFIX}-exportButtonContainer`,
  storyTitleContainer: `${PREFIX}-storyTitleContainer`,
  storyTitle: `${PREFIX}-storyTitle`,
};

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  paddingLeft: '5px',
  paddingTop: '8px',
  '& > .MuiButtonGroup-root': {
    verticalAlign: 'top',
    margin: '4px',
  },
  '& > .MuiButtonGroup-root > button': {
    height: '41px',
    fontSize: '7.5px',
    padding: '3px',
    borderRadius: '5px',
    '& > p': {
      fontSize: '7px',
    },
  },
  '& > .MuiButtonGroup-root > button:nth-of-type(odd)': {
    borderRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& > .MuiButtonGroup-root > button:nth-of-type(even)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  '& > button': {
    height: '41px',
    padding: '3px',
    fontSize: '7.5px',
    borderRadius: '5px',
    margin: '4px',
    '& > p': {
      fontSize: '7px',
    },
  },
  textAlign: 'center',

  [`& .${cls.exportButtonContainer}`]: {
    position: 'absolute',
    right: 24,

    display: 'inline-block',
    marginTop: '4px',
    padding: 0,
  },

  [`& .${cls.storyTitleContainer}`]: {
    left: 0,
    padding: 0,
    position: 'absolute',
    overflow: 'hidden',
    width: 'calc(50% - 250px)',
  },

  [`& .${cls.storyTitle}`]: {
    textAlign: 'left',
    fontSize: 15,
    padding: '14px 24px',
    width: '100vw',
  },
}));

const {
  actionCropPages,
  actionCutAudio,
  actionExclude,
  actionSplit,
  actionSyncAudio,
  executeActionAndWait,
} = Api();

const ModeButton = ({
  currentMode,
  disableEpub = false,
  icon,
  mode,
  name,
  preselected = false,
  sourceType,
  onModeChanged,
}) => {
  const disabled =
    (currentMode !== MODES.DEFAULT && currentMode !== mode) ||
    (disableEpub && sourceType === 'epub');
  const showCancel = currentMode === mode;
  return (
    <IconButton
      {...{ disabled }}
      icon={showCancel ? <CancelIcon /> : icon}
      onClick={() => onModeChanged(mode, preselected)}
      size="large"
    >
      {showCancel ? (
        <span>
          Cancel
          <br />
          {name}
        </span>
      ) : (
        name
      )}
    </IconButton>
  );
};

ModeButton.propTypes = {
  currentMode: PropTypes.string.isRequired,
  disableEpub: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  preselected: PropTypes.bool,
  sourceType: PropTypes.string,
  onModeChanged: PropTypes.func.isRequired,
};

const Toolbar = ({
  className,
  cropState,
  id,
  mode,
  selectedPages,
  sourceType,
  storyTitle,
  onDisableShortcuts,
  onModeChanged,
  onRefresh,
  onShowAddPageDialogChanged,
  onShowAudioSilenceChanged,
  onShowMetadataDialogChanged,
  onShowOffsetAudioDialogChanged,
}) => {
  const notify = useNotify();

  const [showAudioCutDialog, setShowAudioCutDialog] = useState(false);
  const [showAudioPasteDialog, setShowAudioPasteDialog] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState();

  const executeAction = (action, params = []) => {
    const promise = executeActionAndWait(id, action, params, handleProgress)
      .then((data) => {
        setError(data.error);
        setCompleted(data.completed);
        setStatus(data.status);
        if (!data.error) {
          closeProgressDialog();
        }
        return true;
      })
      .catch((ex) => {
        closeProgressDialog();
        notify(`Error: ${ex.message}`, 'warning');
        return false;
      });
    setShowProgressDialog(true);

    return promise;
  };
  const handleProgress = (err, data) => {
    if (err) {
      setError(err);
    }
    setCompleted(data.completed);
    setStatus(data.status);
  };
  const closeProgressDialog = () => {
    setShowProgressDialog(false);
    onRefresh();
  };
  const closeAudioCutDialog = () => {
    slacker('Close audio cutting dialog', { storyId: id });
    onDisableShortcuts(false);
    setShowAudioCutDialog(false);
  };
  const closeAudioPasteDialog = () => setShowAudioPasteDialog(false);

  const handleAudioCut = async (pageCuts) => {
    slacker('Audio cutting started', { storyId: id });
    executeAction(actionCutAudio, [{ pageCuts, preSilence: 0.5, postSilence: 1 }]).then(
      (success) => {
        if (!success) {
          slacker('Audio cutting failed', { storyId: id });
          return;
        }

        slacker('Audio cutting completed', { storyId: id });
        executeAction(actionSyncAudio);
      }
    );
  };

  const handleAudioCutCommand = (text) => {
    const pageCuts = extractAudioCutDetailsFromCommand('"' + text + '"');

    if (!pageCuts) {
      notify(`The command you pasted is not formatted correctly. Check and try again`, 'warning');
    } else {
      closeAudioPasteDialog();
      handleAudioCut(pageCuts);
    }
  };

  const handleSplit = async () => {
    let pagesToSplit = selectedPages
      .map((element, index) => (element ? index : -1))
      .filter((i) => i > -1);

    executeAction(actionSplit, [{ pages: pagesToSplit }]);
  };

  const handleExclude = () => {
    let pagesToExclude = selectedPages
      .map((element, index) => (element ? index : -1))
      .filter((i) => i > -1);

    executeAction(actionExclude, [{ pages: pagesToExclude }]);
  };

  const handleCrop = () => {
    if (cropState) {
      let payload = {
        left: {
          left: cropState[0],
          top: cropState[1],
          width: cropState[2],
          height: cropState[3],
        },
        right: {
          left: cropState[4],
          top: cropState[1],
          width: cropState[2],
          height: cropState[3],
        },
        cover: {
          left: cropState[5],
          top: cropState[1],
          width: cropState[2],
          height: cropState[3],
        },
      };
      executeAction(actionCropPages, [payload]);
    } else {
      onModeChanged(MODES.CROP, true);
    }
  };

  const handleSyncAudio = () => {
    executeAction(actionSyncAudio);
  };

  const openAudioCuttingDialog = () => {
    slacker('Open audio cutting dialog', { storyId: id });
    onDisableShortcuts(true);
    setShowAudioCutDialog(true);
  };

  return (
    <Root className={className}>
      <div className={cls.storyTitleContainer}>
        <div className={cls.storyTitle}>{storyTitle}</div>
      </div>
      <AudioCuttingDialog
        open={showAudioCutDialog}
        onClose={closeAudioCutDialog}
        onCutAudio={handleAudioCut}
      />
      <PasteDialog
        open={showAudioPasteDialog}
        acceptLabel="Cut"
        description={
          <>
            Paste a saved command to directly cut the audio file attached to this story.
            <br />
            <br />
            You can copy a command from the Audio cut main dialog.
          </>
        }
        title="Paste audio cut command"
        onClose={closeAudioPasteDialog}
        onAccept={handleAudioCutCommand}
      />
      <ProgressDialog
        open={showProgressDialog}
        {...{ completed, error, status }}
        onClose={closeProgressDialog}
      />

      <IconButton
        icon={<AssignmentIcon />}
        disabled={mode !== MODES.DEFAULT}
        onClick={() => onShowMetadataDialogChanged(true)}
        size="large"
      >
        Metadata
      </IconButton>
      <ModeButton
        name="Exclude pages"
        currentMode={mode}
        icon={<ExcludeIcon />}
        mode={MODES.EXCLUDE}
        onModeChanged={onModeChanged}
      />
      <ModeButton
        name="Split pages"
        currentMode={mode}
        disableEpub
        icon={<SplitIcon />}
        mode={MODES.SPLIT}
        preselected
        sourceType={sourceType}
        onModeChanged={onModeChanged}
      />
      <ModeButton
        name="Crop pages"
        currentMode={mode}
        disableEpub
        icon={<CropIcon />}
        mode={MODES.CROP}
        preselected
        sourceType={sourceType}
        onModeChanged={onModeChanged}
      />
      <IconButton
        icon={<OffsetIcon />}
        disabled={mode !== MODES.DEFAULT}
        onClick={() => onShowOffsetAudioDialogChanged(true)}
        size="large"
      >
        Offset audio
      </IconButton>
      <IconMultiButton
        actions={[{ label: 'Paste cut command', action: () => setShowAudioPasteDialog(true) }]}
        icon={<GraphicEqIcon />}
        disabled={mode !== MODES.DEFAULT}
        onClick={openAudioCuttingDialog}
      >
        Cut audio
      </IconMultiButton>
      <IconButton
        icon={<SyncIcon />}
        disabled={mode !== MODES.DEFAULT}
        onClick={handleSyncAudio}
        size="large"
      >
        Sync audio
      </IconButton>
      <IconButton
        icon={<SilenceIcon />}
        disabled={mode !== MODES.DEFAULT}
        onClick={() => onShowAudioSilenceChanged(true)}
        size="large"
      >
        Set audio silence
      </IconButton>
      <IconButton
        icon={<AddIcon />}
        disabled={mode !== MODES.DEFAULT || sourceType === 'epub'}
        onClick={() => onShowAddPageDialogChanged(true)}
        size="large"
      >
        Add page
      </IconButton>

      <div className={cls.exportButtonContainer}>
        <BookUploadButton disabled={mode !== MODES.DEFAULT} onOpenChanged={onDisableShortcuts} />
        <br />
        {/*<EpubDownloadButton disabled={mode !== MODES.DEFAULT || sourceType !== 'epub' || true} />*/}
      </div>
      {mode === MODES.EXCLUDE && (
        <>
          <Button variant="execute-action" onClick={handleExclude}>
            Exclude
          </Button>
        </>
      )}
      {mode === MODES.SPLIT && (
        <>
          <Button variant="execute-action" onClick={handleSplit}>
            Split
          </Button>
        </>
      )}
      {mode === MODES.CROP && (
        <>
          <Button variant="execute-action" onClick={handleCrop}>
            CROP
          </Button>
        </>
      )}
    </Root>
  );
};

Toolbar.propTypes = {
  cropState: PropTypes.arrayOf(PropTypes.number),
  id: PropTypes.number,
  mode: PropTypes.string,
  selectedPages: PropTypes.array,
  sourceType: PropTypes.string,
  onDisableShortcuts: PropTypes.func,
  onModeChanged: PropTypes.func,
  onRefresh: PropTypes.func,
  onShowAddPageDialogChanged: PropTypes.func,
  onShowAudioSilenceChanged: PropTypes.func,
  onShowMetadataDialogChanged: PropTypes.func,
  onShowOffsetAudioDialogChanged: PropTypes.func,
};

export default Toolbar;
