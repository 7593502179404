import React, { useCallback, useEffect, useMemo, useLayoutEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

import AudioOffsetSlider from '../../AudioOffsetSlider';
import AudioPreview from './AudioPreview';

const PREFIX = 'PageView';

const cls = {
  select: `${PREFIX}-select`,
  root: `${PREFIX}-root`,
  leftSide: `${PREFIX}-leftSide`,
  leftSideSpacer: `${PREFIX}-leftSideSpacer`,
  leftSideSqueezer: `${PREFIX}-leftSideSqueezer`,
  leftSideText: `${PREFIX}-leftSideText`,
  rightSide: `${PREFIX}-rightSide`,
  image: `${PREFIX}-image`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: 0,
  fontSize: '16px',
  padding: 0,
  [`& .${cls.leftSide}`]: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: theme.palette.gray.dark,
    textAlign: 'left',
    width: '400px',
    height: '100%',
    verticalAlign: 'middle',
  },

  [`& .${cls.leftSideSpacer}`]: {
    display: 'inline-block',
    padding: '20px',
  },

  [`& .${cls.leftSideSqueezer}`]: {
    position: 'absolute',
    top: '220px',
    right: 0,
    cursor: 'pointer',
    // display: 'inline-block',
    padding: '2px',
    paddingTop: '4px',
    verticalAlign: 'middle',
    '& div:hover': {
      backgroundColor: 'gray',
    },
  },

  [`& .${cls.leftSideText}`]: {
    fontFamily: 'Roman',
    padding: '10px',
    width: '100%',
  },

  [`& .${cls.rightSide}`]: {
    display: 'inline-block',
    backgroundColor: theme.palette.gray.dark,
    textAlign: 'center',
    width: 'calc(100% - 400px)',
    height: '100%',
    verticalAlign: 'middle',
  },

  [`& .${cls.image}`]: {
    height: '95%',
    width: '99%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },

  [`& .${cls.buttonContainer}`]: {
    display: 'inline-block',
    width: '400px',
    textAlign: 'center',
  },
}));

const SELECT_STYLES = {
  height: 400,
  select: {
    padding: '6px 0 7px',
    height: '380px !important',
    width: '120px',
    fontSize: '14px',
  },
};

const PageView = ({
  activateKeyShortcuts,
  audioUrl,
  audioSuffix,
  className,
  pageCount,
  pageMatch,
  pageNo,
  pageOffsets,
  wordList,
  image,
  selStart,
  selEnd,
  transcribedText,
  onSelectedWords,
  onSetActivePage,
  onSetNextAfterLast,
  onStartOffsetChange,
  onEndOffsetChange,
}) => {
  const [playbackRate, setPlaybackRate] = useState(+`${localStorage.getItem('playbackRate') || 1}`);
  const [startIndex, setStartIndex] = useState([]);
  const [endIndex, setEndIndex] = useState([]);
  const [lastStartEl, setLastStartEl] = useState(null);
  const [lastEndEl, setLastEndEl] = useState(null);
  const { startWordlist, endWordlist } = useMemo(() => {
    const { words } = wordList || { words: [] };
    let startWordlist = words.map(({ word, start }, idx) => (
      <option id={`start-word-index-${idx}`} key={`word-index-${idx}`} value={idx}>
        {word} ({start.toFixed(2)}s)
      </option>
    ));
    const endWordlist = words.map(({ word, end }, idx) => (
      <option id={`end-word-index-${idx}`} key={`word-index-${idx}`} value={idx}>
        {word} ({end.toFixed(2)}s)
      </option>
    ));

    return { startWordlist, endWordlist };
  }, [wordList]);

  const startTime = useMemo(() => {
    if (!wordList || !wordList.words[startIndex]) {
      return null;
    }
    return wordList.words[startIndex].start + pageOffsets[0];
  }, [pageOffsets, wordList, startIndex]);
  const endTime = useMemo(() => {
    if (!wordList || !wordList.words[endIndex]) return null;
    return wordList.words[endIndex].end + pageOffsets[1];
  }, [pageOffsets, wordList, endIndex]);
  const [rateStyle, setRateStyle] = useState({ 1: { color: '#EC433C' } });

  const updatePlaybackRate = useCallback(({ target }) => {
    setPlaybackRate(Number(target.name));
    setRateStyle({ [target.name]: { color: '#EC433C' } });
    localStorage.setItem('playbackRate', target.name);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    setTimeout(() => {
      if (pageMatch) {
        const idStart = document.getElementById(`start-word-index-${pageMatch.startIndex}`);
        const idEnd = document.getElementById(`end-word-index-${pageMatch.endIndex}`);
        idStart.scrollIntoView({ block: 'center' });
        idEnd.scrollIntoView({ block: 'center' });
        setLastStartEl(idStart);
        setLastEndEl(idEnd);
      } else {
        if (lastStartEl) lastStartEl.scrollIntoView({ block: 'center' });
        if (lastEndEl) lastEndEl.scrollIntoView({ block: 'center' });
      }
    }, 10);
  }, [pageNo, wordList]);
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    setStartIndex(selStart !== null && selStart >= 0 ? [selStart] : []);
  }, [pageNo, selStart]);
  useEffect(() => {
    setEndIndex(selEnd !== null && selEnd >= 0 ? [selEnd] : []);
  }, [pageNo, selEnd]);

  const handleIndexSelected = (index) => (event) => {
    const { options } = event.target;
    const selected = [...options].filter((o) => o.selected)[0];

    if (selected) {
      onSelectedWords(pageNo, index, +selected.value);
    }
  };
  const handleNoWordsClick = useCallback(() => {
    onSelectedWords(pageNo, null);

    if (pageNo + 1 < pageCount) {
      onSetActivePage(pageNo + 1);
    }
  }, [pageCount, pageNo, onSelectedWords, onSetActivePage]);

  const handleReCenterSelects = useCallback(() => {
    if (startIndex?.length) {
      document
        .getElementById(`start-word-index-${startIndex[0]}`)
        .scrollIntoView({ block: 'center' });
    }
    if (endIndex?.length) {
      document.getElementById(`end-word-index-${endIndex[0]}`).scrollIntoView({ block: 'center' });
    }
  }, [startIndex, endIndex]);

  const captureKeyPress = useCallback(
    (e) => {
      if (!activateKeyShortcuts) return;

      switch (e.code) {
        case 'KeyA':
          onSelectedWords(pageNo, 0, Math.max(0, selStart - 1));
          setTimeout(handleReCenterSelects, 10);
          break;
        case 'KeyZ':
          onSelectedWords(pageNo, 0, selStart + 1);
          setTimeout(handleReCenterSelects, 10);
          break;
        case 'KeyS':
          onSelectedWords(pageNo, 1, Math.max(0, selEnd - 1));
          setTimeout(handleReCenterSelects, 10);
          break;
        case 'KeyX':
          onSelectedWords(pageNo, 1, selEnd + 1);
          setTimeout(handleReCenterSelects, 10);
          break;
        case 'ArrowDown':
          if (pageNo + 1 < pageCount) {
            onSetActivePage(pageNo + 1);
          }
          break;
        case 'ArrowUp':
          if (0 < pageNo) {
            onSetActivePage(pageNo - 1);
          }
          break;

        case 'KeyN':
          handleNoWordsClick();
          break;

        case 'KeyR':
          handleReCenterSelects();
          break;

        case 'Enter':
          e.preventDefault();
          e.stopPropagation();
          onSetNextAfterLast();
          break;

        case 'Digit1':
          updatePlaybackRate({ target: { name: '1' } });
          break;

        case 'Digit2':
          updatePlaybackRate({ target: { name: '2' } });
          break;

        case 'Digit3':
          updatePlaybackRate({ target: { name: '3' } });
          break;

        case 'Digit4':
          updatePlaybackRate({ target: { name: '4' } });
          break;

        default:
      }
    },
    [
      activateKeyShortcuts,
      pageNo,
      pageCount,
      selStart,
      selEnd,
      onSetActivePage,
      onSelectedWords,
      handleNoWordsClick,
      handleReCenterSelects,
      onSetNextAfterLast,
      updatePlaybackRate,
    ]
  );
  useEffect(() => {
    document.addEventListener('keydown', captureKeyPress, false);
    return () => {
      document.removeEventListener('keydown', captureKeyPress, false);
    };
  }, [captureKeyPress]);

  return (
    <Root className={className}>
      <div className={cls.leftSide}>
        <div>
          <div className={cls.leftSideSpacer}>
            <InputLabel shrink htmlFor="start-word-select">
              First word on page
            </InputLabel>
            <Tooltip
              placement="top"
              title="Välj vilken bokstav som sidan börjar med.&#10;&#13;[A] till förra ordet, [Z] till nästa ord"
              // variant="light"
            >
              <Select
                multiple
                native
                sx={SELECT_STYLES}
                variant="filled"
                value={startIndex}
                inputProps={{
                  id: 'start-word-select',
                }}
                onChange={handleIndexSelected(0)}
              >
                {startWordlist}
              </Select>
            </Tooltip>
          </div>
          <div className={cls.leftSideSpacer}>
            <InputLabel shrink htmlFor="end-word-select">
              Last word on page
            </InputLabel>
            <Tooltip
              placement="top"
              title="Välj vilken bokstav som sidan slutar med.&#10;&#13;[S] till förra ordet, [X] till nästa ord"
              // variant="light"
            >
              <Select
                multiple
                native
                sx={SELECT_STYLES}
                variant="filled"
                value={endIndex}
                onChange={handleIndexSelected(1)}
                inputProps={{
                  id: 'end-word-select',
                }}
              >
                {endWordlist}
              </Select>
            </Tooltip>
          </div>
          <div className={cls.leftSideSqueezer}>
            <div onClick={handleReCenterSelects}>
              <Tooltip
                placement="top"
                title="[R] Re-center the select box on selected words"
                variant="light"
              >
                <VerticalAlignCenterIcon />
              </Tooltip>
            </div>
            <div onClick={onSetNextAfterLast}>
              <Tooltip title="[Enter] Mark page as done and move to next page" variant="light">
                <VerticalAlignBottomIcon />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={cls.buttonContainer}>
          {(selStart !== null || selEnd !== null) && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <AudioOffsetSlider value={(pageOffsets || [])[0]} onChange={onStartOffsetChange}>
                    Start offset
                  </AudioOffsetSlider>
                </Grid>
                <Grid item xs={6}>
                  <AudioOffsetSlider value={(pageOffsets || [])[1]} onChange={onEndOffsetChange}>
                    End offset
                  </AudioOffsetSlider>
                </Grid>
              </Grid>
              <Tooltip
                placement="top"
                title="[N] Mark this page to not have any words to read"
                variant="light"
              >
                <button onClick={handleNoWordsClick}>no words on this page</button>
              </Tooltip>
              <br />
              <br />
            </>
          )}
          <AudioPreview
            activateKeyShortcuts={activateKeyShortcuts}
            audioUrl={audioUrl}
            audioSuffix={audioSuffix}
            startTime={startTime}
            endTime={endTime}
            playbackRate={playbackRate}
          />
          <br />
          {[1, 1.25, 1.5, 2].map((x, i) => (
            <Tooltip key={x} title={`[${i + 1}] Set playback speed to ${x * 100}%`} variant="light">
              <button name={`${i + 1}`} style={rateStyle[i + 1]} onClick={updatePlaybackRate}>
                {`${x * 100}%`}
              </button>
            </Tooltip>
          ))}
        </div>
        <div className={cls.leftSideText}>{transcribedText}</div>
      </div>
      <div className={cls.rightSide}>
        <div className={cls.image} style={{ backgroundImage: `url(${image})` }} />
      </div>
    </Root>
  );
};

PageView.propTypes = {};

export default PageView;
