import React, { useState, useEffect } from 'react';

import { useNotify } from 'react-admin';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Api from '../../dataProvider/index';

const { actionAddPage } = Api();

const AddPageDialog = ({ open, onClose, id, activePage }) => {
  const notify = useNotify();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(activePage);
  }, [activePage]);

  async function send() {
    try {
      await actionAddPage(id, { pageIndex: pageIndex, imageId: '_blank' });
    } catch (ex) {
      notify(`Error: ${ex.message}`, 'warning');
    }
    onClose(null, true);
  }

  function updatePageIndex(evt) {
    setPageIndex(evt.target.value);
  }

  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add new page</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText component={'span'} id="alert-dialog-description">
          <div>Insert blank page at position</div>
          <div>
            <input value={pageIndex} onChange={updatePageIndex} type="number" step="1" />
          </div>
        </DialogContentText>
        <Button variant="book-action" onClick={send}>Add page</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPageDialog;
