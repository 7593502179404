import React from 'react';
import { Show, SimpleShowLayout, TextField, UrlField } from 'react-admin';

const ShowTranscriptions = (props) => {
  return (
    <Show {...props} actions={false}>
      <SimpleShowLayout>
        <TextField source="isbn" />
        <TextField source="audioFilename" />
        <TextField source="progress" />
        <UrlField source="url" label="Link to source files" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowTranscriptions;
