import React from 'react';
import {
  Create,
  FileInput,
  FileField,
  SimpleForm,
  TextInput,
  TopToolbar,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';
import Button from '@mui/material/Button';

import { MIME_TYPES } from '../../utils/mimeTypes';

import Api from '../../dataProvider/index';

const { create } = Api();

const Actions = ({ resource }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const handleClick = () => {
    /*eslint no-restricted-globals: ["warn", "event"]*/
    if (!confirm('Do you want to load ALL metadata from Source files')) return;

    console.log('resource', resource);

    create(resource, { data: { files: [], all: true } })
      .then(({ data }) => {
        console.log('data', data);
        notify(`resources.audio.notifications.uploadStarted`);
        redirect(`/metadatas/${data.id}/show`);
        refresh();
      })
      .catch((ex) => {
        alert(ex.message);
      });
  };

  return (
    <TopToolbar>
      <Button color="primary" variant="contained" onClick={handleClick}>
        Load ALL metadata
      </Button>
    </TopToolbar>
  );
};

const CreateMetadatas = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`resources.audio.notifications.uploadStarted`);
    redirect(`/metadatas/${data.id}/show`);
    refresh();
  };

  return (
    <div>
      <Create actions={<Actions />} onSuccess={onSuccess} {...props}>
        <SimpleForm>
          <TextInput
            multiline
            label="ISBN from Source Files"
            name="isbns"
            source="isbns"
            inputProps={{ maxRows: 10 }}
          />
          <FileInput
            accept={MIME_TYPES.xml}
            label="Upload xml (onix) files to create a csv import to PIM"
            multiple
            name="files"
            source="files"
            sx={{
              '& .RaFileInput-dropZone': {
                backgroundColor: 'backdrops.green',
              },
            }}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default CreateMetadatas;
