import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import StartDialog from './StartDialog';

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-8px',
  height: 'calc(100vh)',
  left: 0,
  right: 0,
  marginLeft: -8,
  marginRight: -8,
  marginBottom: -8,
  background: theme.palette.background.default,
  verticalAlign: 'middle',
}));
const StartLabel = styled('div')(() => ({
  textAlign: 'center',
  lineHeight: 'calc(95vh)',
  height: '100%',
  fontSize: '26px',
  cursor: 'pointer',
}));

const Home = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Root>
      <StartLabel onClick={() => setIsOpen(true)}>START</StartLabel>
      <StartDialog open={isOpen} onClose={() => setIsOpen(false)} />
    </Root>
  );
};

export default Home;
