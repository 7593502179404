import React from 'react';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import IconButton from './IconButton';

const StyledButtonGroup = styled(ButtonGroup)({
  boxShadow: 'none',
  border: 0,
  padding: 0,
  margin: '8px',
  marginRight: 0,
});
const StyledButton = styled(Button)({
  border: '1px solid #aaa',
  background: 'transparent',
  padding: 0,
  minWidth: '15px !important',
  width: '15px',
  '&:hover': {
    background: 'rgba(255,255,255,0.4)',
    boxShadow: 'none',
  },
  '& span': {
    margin: '-10px',
    padding: 0,
  },
});

export default function IconMultiButton({ children, actions, icon, onClick }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <IconButton inGroup icon={icon} onClick={onClick} size="large">
          {children}
        </IconButton>
        <StyledButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon fontSize="small" />
        </StyledButton>
      </StyledButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actions.map(({ label, action, disabled }) => (
                    <MenuItem key={label} disabled={disabled} onClick={action}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
