import React from 'react';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';


const StyledTextField = styled(TextField)({
  minWidth: '230px',
  '& textarea': {
    fontFamily: 'Courier New',
    fontSize: '10px',
  },
});

const WeekStats = ({ text }) => {
  return (
    <div>
      <form noValidate autoComplete="off">
        <StyledTextField
          aria-readonly={true}
          id="outlined-basic"
          label="Book stats this week"
          multiline
          size="medium"
          variant="outlined"
          value={text}
          onChange={() => {}}
        />
      </form>
    </div>
  );
};

export default WeekStats;
