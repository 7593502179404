import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import MetadatasField from '../../components/fields/MetadatasField';

const ShowTranscriptions = (props) => {
  return (
    <Show {...props} actions={false}>
      <SimpleShowLayout>
        <MetadatasField />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowTranscriptions;
