import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useNotify, useRecordContext } from 'react-admin';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import ThernUploadDialog from '../common/dialogs/ThernUploadDialog';
import Api, { createThernBook, patchThernBookMeta } from '../../dataProvider/index';
import slacker from '../../dataProvider/slacker';

const Root = styled('div')({
  cursor: 'pointer',
  display: 'inline-block',
  margin: 0,
  padding: '1px 0 0 10px',
  textAlign: 'center',
});

const ColorButton2 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  borderRadius: '16px',
  color: 'white',
  fontSize: '10px',
  height: '32px',
  margin: '0px',
  width: '150px',
}));

const { updatePatch, upload } = Api();

const BookUploadButton = (props) => {
  const notify = useNotify();
  const [ts, setTs] = useState(Date.now());
  const { id: enriquePdfId, regions, title, metadata, pages } = useRecordContext(props);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newBookId, setNewBookId] = useState(null);
  const [status, setStatus] = useState('');

  const handleClickOpen = () => {
    slacker('Open upload dialog', { storyId: enriquePdfId });
    setCompleted(false);
    setOpen(true);
    props.onOpenChanged(true);
  };

  const handleUploadBook = async (bookId, metaData, selectedBookMetadata, options) => {
    const isbn = metaData.isbn || (selectedBookMetadata || {}).isbn;
    if (!isbn || isbn.length < 13) {
      notify(`Aborted: ISBN must be set (at least 13 digits)!`, 'error');
      return;
    }

    const { audioEncoding } = options;
    const pageCount = pages.length;
    const wordCount = (pages || []).reduce((c, { text }) => text.split(' ').length + c, 0);

    slacker('Uploading book', { storyId: enriquePdfId, isbn, pageCount, wordCount, audioEncoding });

    setUploading(true);
    setCompleted(false);

    metaData.regions = regions;

    if (!bookId) {
      try {
        const newBook = await createThernBook(metaData);
        const newBookId = newBook._id;

        await updatePatch(enriquePdfId, { bookId: newBookId });
        setNewBookId(newBookId);
      } catch (ex) {
        console.error(ex);
        setError(ex.message);
        notify(`Error: ${ex.message}`, 'warning');
        setUploading(false);
        return;
      }
    } else {
      try {
        await patchThernBookMeta(bookId, metaData);
        await updatePatch(enriquePdfId, { bookId });
      } catch (ex) {
        console.error(ex);
        setError(ex.message);
        notify(`Error: ${ex.message}`, 'warning');
        return;
      }
    }

    return upload(enriquePdfId, { audioEncoding }, (err, data) => {
      setError(err);
      setCompleted(data.completed);
      setStatus(data.status);
      if (err) {
        slacker('Uploading failed', { storyId: enriquePdfId });
      } else if (data.completed) {
        slacker('Uploading completed', { storyId: enriquePdfId, isbn });
      }
    }).catch((ex) => {
      console.warn(ex.message);
      setError(ex.message);
    });
  };

  const handleClose = () => {
    if (completed || error) {
      setTs(Date.now());
    }
    setOpen(false);
    setError(null);
    setCompleted(false);
    setUploading(false);
    setStatus('');
    props.onOpenChanged(false);
  };

  return (
    <Root>
      <ThernUploadDialog
        key={ts}
        newBookId={newBookId}
        {...{
          completed,
          initialSearch: (metadata || {}).title || title,
          initialMetadata: metadata,
          open,
          uploading,
          uploadCompleted: completed,
          uploadError: error,
          uploadStatus: status,
          onClose: handleClose,
          onUploadBookClick: handleUploadBook,
        }}
      />
      <ColorButton2
        color="primary"
        disabled={props.disabled}
        variant="contained"
        onClick={handleClickOpen}
      >
        Upload to Boksnok
      </ColorButton2>
    </Root>
  );
};

BookUploadButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
};

export default BookUploadButton;
