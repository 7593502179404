import React from 'react';
import PropTypes from 'prop-types';

import PagePreviewSmall from './PagePreviewSmall';

const STYLES = {
  background: '#333',
  padding: '0px 5px',
  overflowY: 'scroll',
};

const Pages = ({ className, activePage, pages, pageMatches, userSelected, onPageClick }) => {
  return (
    <div className={className} style={STYLES}>
      {pages.map((_, pageNumber) => (
        <PagePreviewSmall
          isActive={pageNumber === activePage}
          key={`${pageNumber}`}
          pageNumber={pageNumber}
          similarity={(pageMatches[pageNumber] || {}).similarity}
          userSelection={userSelected[pageNumber]}
          onClick={onPageClick}
        />
      ))}
    </div>
  );
};

Pages.propTypes = {
  activePages: PropTypes.array,
  handlePageClick: PropTypes.func,
  handlePageSelect: PropTypes.func,
  pages: PropTypes.array,
  selectable: PropTypes.bool,
  selectedPages: PropTypes.array,
};

export default Pages;
