import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTranslate, Title } from 'react-admin';
import RelativeDate from 'relative-date';

import parentPackage from 'parentPackage';
import Config from '../../config';
import { getHealth } from '../../dataProvider';

const frontendVersion = parentPackage.version;

const StyledHeader = styled('div')({
  display: 'block',
  fontFamily: 'Fredoka One',
  fontSize: '22px',
  margin: '1em 0',
  width: '100%',
});
const StyledLabel = styled('div')({
  width: '100%',
  display: 'inline-block',
  fontFamily: 'Karla',
});

const SystemInfo = () => {
  const translate = useTranslate();

  const [loaded, setLoaded] = useState(false);
  const [healthData, setHealthData] = useState({ config: {}, version: null });
  const { config = {}, startedAt, version: backendVersion } = healthData;

  useEffect(() => {
    getHealth()
      .then(setHealthData)
      .then(() => {
        setLoaded(true);
      });
  }, []);

  return (
    <Card>
      <Title title={translate('pos.systemInfo')} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} sm={6} md={6} alignItems="flex-start">
            <div>
              <StyledHeader>Frontend</StyledHeader>
              <StyledLabel>Versions</StyledLabel>
              <ul>
                <li>Admin: {frontendVersion}</li>
              </ul>
              <StyledLabel>Configurations</StyledLabel>
              <ul>
                {Object.keys(Config).map((name) => (
                  <li key={name}>
                    {name}: {Config[name]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid container item xs={12} sm={6} md={6} alignItems="flex-start">
            <StyledHeader>Backend</StyledHeader>
            {!loaded && <p>Loading...</p>}
            {loaded && (
              <>
                <StyledLabel>Versions</StyledLabel>
                <ul>
                  <li>Backend: {backendVersion}</li>
                </ul>
                <StyledLabel>Configurations</StyledLabel>
                <ul>
                  {Object.keys(config).map((name) => (
                    <li key={name}>
                      {name}: {config[name]}
                    </li>
                  ))}
                </ul>
                <StyledLabel>Restarted {RelativeDate(new Date(startedAt))}</StyledLabel>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SystemInfo;
