import React from 'react';

import { Layout } from 'react-admin';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AppBar from './AppBar';
import { defaultTheme } from './theme';

const PREFIX = 'Layout';

const classes = {
  overrides: `${PREFIX}-overrides`,
};

const StyledLayout = styled(Layout)({
  [`& .${classes.overrides}`]: {
    padding: 0,
    minHeight: 'auto',
    '& main': {
      height: 'calc(100vh - 179px)',
    },
  },
});

const EmptySidebar = () => <></>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <StyledLayout
      {...props}
      appBar={AppBar}
      className={classes.overrides}
      sidebar={EmptySidebar}
      theme={defaultTheme}
    >
      <CssBaseline />
      {props.children}
    </StyledLayout>
  );
};
