import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function SelectField({ label, id, value, onChange, children, className }) {
  return (
    <FormControl className={className}>
      <InputLabel>{label}</InputLabel>
      <Select id={id} value={value} onChange={onChange}>
        {children}
      </Select>
    </FormControl>
  );
}

SelectField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
