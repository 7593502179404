import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/NoteAdd';
import FolderIcon from '@mui/icons-material/FolderOpen';
import FilesIcon from '@mui/icons-material/Description';
import StatsIcon from '@mui/icons-material/InsertChart';

import Metadatas from '../resources/metadatas';
import Transcriptions from '../resources/transcriptions';

import BigActionButton from '../components/common/BigActionButton';

const StartDialog = ({ open, onClose }) => {
  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Welcome to Enrique</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <BigActionButton href="/#/stories/create" icon={<AddIcon />}>
          Create a new book
        </BigActionButton>

        <BigActionButton href="/#/stories" icon={<FolderIcon />}>
          Open an existing project
        </BigActionButton>

        <br />

        <BigActionButton href="/#/transcriptions" icon={<Transcriptions.Icon />}>
          Audio transcriptions
        </BigActionButton>

        <BigActionButton href="/#/metadatas/create" icon={<Metadatas.Icon />}>
          Create Onix imports
        </BigActionButton>

        <BigActionButton href="/#/source-files" icon={<FilesIcon />}>
          Show source files
        </BigActionButton>

        <br />

        <BigActionButton href="/#/stats" icon={<StatsIcon />}>
          Book stats
        </BigActionButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartDialog;
