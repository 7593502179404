import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecordContext, useRefresh, useStore } from 'react-admin';
import { styled } from '@mui/material/styles';

import Api from '../../dataProvider/index';
import { PLAYBACK_MODES, MODES, STORE } from '../../constants';
import AudioSilenceDialog from '../book-preview/AudioSilenceDialog';
import AddPageDialog from '../book-preview/AddPageDialog';
import EditMetadataDialog from '../book-preview/EditMetadataDialog';
import OffsetAudioDialog from '../book-preview/OffsetAudioDialog';
import MainView from '../book-preview/MainView';

const { updatePatch } = Api();

const StyledBookPreviewField = styled('div')({
  backgroundColor: 'background.default',
});

const BookPreviewField = (props) => {
  const refresh = useRefresh();
  const [showAddPageDialog, setShowAddPageDialog] = useState(false);
  const [showAudioSilenceDialog, setShowAudioSilenceDialog] = useState(false);
  const [showMetadataDialog, setShowMetadataDialog] = useState(false);
  const [showOffsetAudioDialog, setShowOffsetAudioDialog] = useState(false);
  // const [playbackPage, setPlaybackPage] = useState(0);
  // const [activePage, setActivePage] = useState(0);
  const [playbackMode, setPlaybackMode] = useState(PLAYBACK_MODES.QUICK);
  const [playbackRate, setPlaybackRate] = useState(1);
  const {
    createdAt,
    pages,
    id,
    files,
    silence,
    sourceType,
    title,
    metadata,
    updatedAt,
  } = useRecordContext(props);
  const pageCount = pages.length;
  const [storyPage, setStoryPage] = useStore(STORE.STORY_PAGE, 0);

  const [mode, setMode] = useState(MODES.DEFAULT);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [disableAudioControls, setDisableAudioControls] = useState(false);
  const [cropState, setCropState] = useState(null);

  const ts = useMemo(() => new Date(updatedAt || createdAt).getTime(), [createdAt, updatedAt]);

  const activePage = storyPage.id === id ? Math.min(storyPage.page, pageCount - 1) : 0;
  const playbackPage = activePage;
  function setActivePage(page) {
    setStoryPage({ id, page });
  }

  // On component load
  useEffect(() => {
    if (localStorage.fullPlay === 'true') {
      setPlaybackMode(PLAYBACK_MODES.FULL);
    }
    const storedPlaybackRateStr = localStorage.getItem('playbackRate');
    if (storedPlaybackRateStr) {
      const rate = Number(storedPlaybackRateStr);
      if (Number.isNaN(rate) || rate < 0.1 || rate > 4) {
        return;
      }
      setPlaybackRate(rate);
    }
  }, []);

  const playClicked = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    } else {
      setIsPaused(!isPaused);
    }
  };
  const handlePlaybackCompleted = (page) => {
    console.log(`Playback completed for page ${page}`);
    setTimeout(() => {
      if (page + 1 === pageCount) {
        setIsPaused(false);
        setIsPlaying(false);
        setPlayPage(0);
        return;
      }
      setPlayPage(page + 1);
    }, 100);
  };

  const setPlayPage = (pageNumber) => {
    if (mode === MODES.CROP) {
      if (activePage === 0 || (activePage !== 0 && pageNumber === 0)) return;
    }
    // setPlaybackPage(pageNumber);
    setActivePage(pageNumber);
  };

  const handleSetPlaybackRate = (rate) => {
    setPlaybackRate(rate);
    localStorage.playbackRate = rate;
  };

  const handlePageClick = (pageNumber) => {
    setPlayPage(pageNumber);
  };
  const handlePageSelect = (pageNumber) => {
    let newSelected = [...selectedPages];
    newSelected[pageNumber] = !newSelected[pageNumber];
    setSelectedPages(newSelected);
  };
  const handleUpdateSelectedPages = (selectedPages) => {
    setSelectedPages(selectedPages);
  };

  const changeMode = (newMode, preselected = false) => {
    const modeToSet = mode === newMode ? MODES.DEFAULT : newMode;
    setDisableAudioControls(modeToSet !== MODES.DEFAULT);
    setMode(modeToSet);
    setSelectedPages([...Array(pageCount)].map(() => preselected));
  };

  const closeAudioSilenceDialog = () => setShowAudioSilenceDialog(false);
  const handleSetAS = (duration) => {
    updatePatch(id, { silence: duration })
      .then(() => {
        refresh();
      })
      .catch((e) => alert(`Det gick inte att patcha: ${e.message}`));
  };

  const closeAddPageDialog = (evt, reload = false) => {
    setShowAddPageDialog(false);
    if (reload) {
      refresh();
    }
  };

  const closeMetadataDialog = (evt, reload = false) => {
    setShowMetadataDialog(false);
    if (reload) {
      refresh();
    }
  };

  const closeOffsetAudioDialog = (evt, reload = false) => {
    setShowOffsetAudioDialog(false);
    if (reload) {
      refresh();
    }
  };

  let leftPageNumber = activePage;
  let rightPageNumber = null;
  if (activePage > 0) {
    rightPageNumber = activePage + 1;
    if (activePage % 2 === 0) {
      rightPageNumber = activePage;
      leftPageNumber = activePage - 1;
    }
    if (rightPageNumber >= pages.length) {
      rightPageNumber = null;
    }
  }

  const selectable = mode !== MODES.DEFAULT && mode !== MODES.CROP;

  return (
    <StyledBookPreviewField>
      <OffsetAudioDialog
        activePage={activePage}
        open={showOffsetAudioDialog}
        onClose={closeOffsetAudioDialog}
        id={id}
      />
      <AudioSilenceDialog
        open={showAudioSilenceDialog}
        silence={silence}
        onClose={closeAudioSilenceDialog}
        onSetDuration={handleSetAS}
      />
      <AddPageDialog
        open={showAddPageDialog}
        onClose={closeAddPageDialog}
        id={id}
        activePage={activePage}
      />
      <EditMetadataDialog
        metadata={metadata}
        open={showMetadataDialog}
        onClose={() => closeMetadataDialog()}
      />

      <MainView
        changeMode={changeMode}
        cropState={cropState}
        disableAudioControls={disableAudioControls}
        files={files}
        handlePlaybackCompleted={handlePlaybackCompleted}
        id={id}
        isPaused={isPaused}
        isPlaying={isPlaying}
        leftPageNumber={leftPageNumber}
        mode={mode}
        pages={pages}
        playbackMode={playbackMode}
        playbackPage={playbackPage}
        playbackRate={playbackRate}
        playClicked={playClicked}
        refresh={refresh}
        rightPageNumber={rightPageNumber}
        selectable={selectable}
        selectedPages={selectedPages}
        storyTitle={title || files[0].meta.originalName}
        setCropState={setCropState}
        setDisableAudioControls={setDisableAudioControls}
        setPlaybackMode={setPlaybackMode}
        setPlaybackRate={handleSetPlaybackRate}
        setPlayPage={setPlayPage}
        setShowAddPageDialog={setShowAddPageDialog}
        setShowAudioSilenceDialog={setShowAudioSilenceDialog}
        setShowMetadataDialog={setShowMetadataDialog}
        setShowOffsetAudioDialog={setShowOffsetAudioDialog}
        sourceType={sourceType}
        ts={ts}
        onPageClick={handlePageClick}
        onPageSelect={handlePageSelect}
        onUpdateSelectedPages={handleUpdateSelectedPages}
      />
    </StyledBookPreviewField>
  );
};

BookPreviewField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export default BookPreviewField;
