import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

const Button = styled('button')(({ disabled, inGroup, outlined, small, theme }) => ({
  height: small ? '41px' : null,
  background: 'transparent',
  color: theme.palette.lightgray.main,
  textAlign: 'center',
  display: 'inline-block',
  fontSize: small ? 7.5 : 15,
  textDecoration: 'none',
  border: outlined ? '1px solid #aaa' : 0,
  borderRight: inGroup ? 0 : undefined,
  padding: small ? 3 : 8,
  cursor: disabled ? 'default' : 'pointer',
  borderRadius: outlined ? (small ? 5 : 10) : 0,
  borderTopRightRadius: inGroup ? 0 : undefined,
  borderBottomRightRadius: inGroup ? 0 : undefined,
  margin: inGroup ? 0 : small ? 4 : 8,
  marginRight: 0,
  verticalAlign: 'top',
  '&:hover': {
    background: 'rgba(255,255,255,0.4)',
  },
  opacity: disabled ? 0.2 : 1,
}));
const Icon = styled('div')({
  height: '16px',
  '& > svg': {
    fontSize: '22px',
  },
});
const Text = styled('p')(({ small, theme }) => ({
  fontFamily: 'Karla, sans-serif',
  color: theme.palette.lightgray.main,
  fontSize: small ? 7 : 14,
  userSelect: 'none',
}));

const IconButton = ({ children, disabled, icon, inGroup, outlined, small, onClick }) => {
  const clicked = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Button {...{ disabled, inGroup, outlined, small }} onClick={clicked}>
      <Icon>{icon}</Icon>
      <Text>{children}</Text>
    </Button>
  );
};

IconButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  color: 'primary',
  disabled: false,
  outlined: true,
  small: true,
};

export default IconButton;
