import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import dataProviderFactory from './dataProvider';

const prepareDataProvider = async () => {
  return { dataProvider: dataProviderFactory() };
};

prepareDataProvider().then(({ dataProvider }) => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App dataProvider={dataProvider} />
    </React.StrictMode>
  );
});
