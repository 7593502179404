import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Title } from 'react-admin';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import WeekStats from './WeekStats';
import { getHeadersWithAuth, getSessionId } from '../../dataProvider/helpers';

import { BASE_URL } from '../../config';
import { getWeeksDiff } from './utils';
import WeekDiffs from './WeekDiffs';

const StyledCard = styled(Card)({
    overflowY: 'auto',
    fontFamily: 'Karla',
});
const StyledHeader = styled('div')({
  display: 'block',
  fontWeight: 'bold',
  fontSize: '22px',
  margin: '1em 0',
  width: '100%',
})

const URL_PREFIX = `${BASE_URL}/stats/count`;

const Stats = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [dachDiff, setDachDiff] = useState(null);
  const [sweDiff, setSweDiff] = useState(null);

  useEffect(() => {
    if (!getSessionId()) {
      return;
    }

    setLoading(true);
    fetch(`${URL_PREFIX}`, {
      ...getHeadersWithAuth(),
    })
      .then(async (res) => {
        if (res.status === 401) {
          setError('You are not logged in');
          return;
        }
        const {
          numbers,
          text,
          weekNumber,
          dachLauraCheck,
          dachQACount,
          sweQACount,
          sweReadyToPublishCount,
        } = await res.json();
        setText(text);

        const weeksDiff = getWeeksDiff(
          weekNumber,
          numbers,
          [sweQACount, sweReadyToPublishCount],
          [dachQACount + dachLauraCheck]
        );

        if (!weeksDiff) return;
        console.log(weeksDiff);

        setSweDiff(weeksDiff.swe);
        setDachDiff(weeksDiff.dach);
      })
      .catch((ex) => {
        setError(ex.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <StyledCard>
      <Title title="Stats" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={0} sm={3} md={3} alignItems="flex-start" />
          <Grid container item xs={12} sm={6} md={6} alignItems="flex-start">
            <div>
              <StyledHeader>Book stats</StyledHeader>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid container item xs={0} sm={3} md={3} alignItems="flex-start" />
          <Grid container item xs={12} sm={3} md={3} alignItems="flex-start">
            <div style={{ width: '100%' }}>
              <WeekStats text={text} />
            </div>
          </Grid>
          <Grid container item xs={12} sm={3} md={3} alignItems="flex-start">
            <div style={{ width: '100%' }}>
              <WeekDiffs swedish={sweDiff} german={dachDiff} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default Stats;
