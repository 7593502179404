import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DURATIONS = [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500];

const AudioSilenceDialog = ({ open, silence, onClose, onSetDuration }) => {
  const set = (duration) => {
    onSetDuration(duration);
    onClose();
  };

  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Set audio silence duration</DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'} id="alert-dialog-description" style={{ textAlign: 'center' }}>
          <div>
            <p>Select how long silence (in millisec), each page without audio, should have.</p>
          </div>
          {DURATIONS.map((duration, index) => {
            const color = silence === duration ? 'secondary' : 'primary';
            return (
              <Button variant="book-action" {...{ color }} onClick={() => set(duration)} key={'silence' + index}>
                {duration} ms
              </Button>
            );
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button></DialogActions>
    </Dialog>
  );
};

export default AudioSilenceDialog;
