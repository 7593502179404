import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  pos: {
    search: 'Search',
    systemInfo: 'System Information',
    language: 'Language',
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to Boksnok Enrique',
        subtitle:
          'This is the admin of the future. Build stories with our WYSIWYG awesome interface!',
        upload_button: 'Upload PDFs',
        uploaded_button: 'Show uploaded PDFs',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    stories: {
      name: 'Uploaded story |||| Uploaded stories',
      notifications: {
        uploadStarted: 'Uploading story files...',
        uploadCompleted: 'Upload completed. Starting processing of story...',
      },
      fields: {
        animations: {
          helperText: 'On which pages should animations be added? Separated with ","',
          label: 'Animations',
        },
        bookId: {
          label: 'Thern Book Id',
        },
        title: {
          label: 'Book title',
        },
      },
      tabs: {
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
    },
    transcriptions: {
      notifications: {
        transcriptionInProgress: 'Files uploaded, transcription is in progress...',
        uploadCompleted: 'Files uploaded!',
        uploadStarted: 'Uploading of files started...',
      },
    },
  },
};

export default customEnglishMessages;
