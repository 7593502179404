import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import CheckBoxChecked from '@mui/icons-material/CheckBox';
import CheckBoxUnchecked from '@mui/icons-material/CheckBoxOutlineBlank';

import audioIcon from '../../assets/images/icons/audio.png';

const PREFIX = 'PagePreviewSmall';

const cls = {
  root: `${PREFIX}-root`,
  bgImage: `${PREFIX}-bgImage`,
  pageNumber: `${PREFIX}-pageNumber`,
  checkbox: `${PREFIX}-checkbox`,
  audioIcon: `${PREFIX}-audioIcon`,
  divider: `${PREFIX}-divider`,
};

const Root = styled('div')(({ active, isCover, theme, selectable }) => ({
  '&:hover': {
    color: 'white',
  },
  border: active ? `3px solid ${theme.palette.secondary.main}` : 'none',
  boxSizing: 'border-box',
  cursor: selectable ? 'zoom-in' : null,
  display: isCover ? 'block' : 'inline-block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '22px',
  outlineOffset: '-4px',
  position: 'relative',
  textAlign: 'center',
  width: '50%',
}));

const AudioIcon = styled('img')(({ hasAudio }) => ({
  background: 'rgba(255, 255, 255, 0.7)',
  border: '1px solid #666',
  borderRadius: 12,
  display: hasAudio ? undefined : 'none',
  height: 18,
  position: 'absolute',
  right: 1,
  top: -4,
  width: 18,
}));
const CheckBoxContainer = styled('div')(({ selectable, theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.gray.main,
  pointerEvents: selectable ? 'unset' : 'none',
  opacity: selectable ? 1 : 0,
  transition: 'opacity 0.25s ease-out',
  cursor: 'pointer',
  fontSize: 'larger',
  position: 'absolute',
  padding: '10px',
  top: 'calc(50% - 25px)',
  left: 'calc(50% - 25px)',
}));
const PageImage = styled('img')({
  width: '100%',
  boxShadow: `inset 0px 0px 0px 23px black`,
});
const PageNumber = styled('div')({
  bottom: '-16px',
  color: 'inherit',
  cursor: 'default',
  fontSize: '12px',
  left: 0,
  position: 'absolute',
  transition: 'color 0.2s',
  width: '100%',
});
const Divider = styled('div')({
  backgroundColor: 'black',
  bottom: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: 1,
});

const CheckboxIcons = {
  true: CheckBoxChecked,
  false: CheckBoxUnchecked,
};

const PagePreviewSmall = ({
  active,
  backgroundImage,
  hasAudio,
  isCover,
  isSelected,
  page,
  selectable,
  ts,
  onClick,
  onSelect,
}) => {
  const { url: bgUrl } = backgroundImage.file;
  const CheckboxIcon = CheckboxIcons[isSelected];
  const ref = useRef();

  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect(page);
  };

  useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [active]);

  return (
    <Root
      {...{ active, isCover, selectable }}
      ref={ref}
      style={{ cursor: selectable ? 'zoom-in' : null }}
      onClick={() => onClick(page)}
    >
      <AudioIcon alt="Has audio" hasAudio={hasAudio} src={audioIcon} />
      <PageImage alt={`Page ${page}`} src={`${bgUrl}?ts=${ts}`} />
      <CheckBoxContainer
        selectable={selectable}
        onClick={handleSelect}
      >
        <CheckboxIcon fontSize="large" />
      </CheckBoxContainer>
      <PageNumber className={cls.pageNumber}>
        <span>{isCover ? 'cover' : page}</span>
      </PageNumber>
      {page % 2 && <Divider />}
    </Root>
  );
};

PagePreviewSmall.propTypes = {
  isCover: PropTypes.bool,
  page: PropTypes.number,
  backgroundImage: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,

  // Selection mode
  selectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,

  hasAudio: PropTypes.bool,
};

PagePreviewSmall.defaultProps = {
  cover: false,

  selectable: false,
  isSelected: false,
  active: false,
  hasAudio: false,
};

export default PagePreviewSmall;
