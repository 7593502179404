import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Logout, UserMenu, useTranslate } from 'react-admin';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/NoteAdd';
import FilesIcon from '@mui/icons-material/Description';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import FolderIcon from '@mui/icons-material/FolderOpen';
import InfoIcon from '@mui/icons-material/Info';

import Transcriptions from '../resources/transcriptions';
import Metadatas from '../resources/metadatas';

const CenterOfAttention = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: 'center',
  '& svg': {
    color: theme.palette.lightgray.main,
    margin: '10px 10px',
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontWeight: 'bolder',
  fontSize: '20px',
  marginLeft: '10px',
  '& a': {
    marginLeft: '14px',
    color: 'unset',
    textDecoration: 'none',
  },
  position: 'absolute',
}));

const Spacer = styled('span')(() => ({ flex: 1 }));

const ConfigurationMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      ref={ref}
      to="/system-info"
      {...props}
    >
      <ListItemIcon>
        <InfoIcon />
      </ListItemIcon>
      <ListItemText>{translate('pos.systemInfo')}</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props) => {
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <CenterOfAttention>
        <a href="/#/stories/create" title="Create new Story">
          <Tooltip title="Create new Story">
            <AddIcon />
          </Tooltip>
        </a>
        <a href="/#/stories">
          <Tooltip title="Open Story">
            <FolderIcon />
          </Tooltip>
        </a>
        <a href="/#/transcriptions">
          <Tooltip title="Audio transcription">
            <Transcriptions.Icon />
          </Tooltip>
        </a>
        <a href="/#/metadatas/create">
          <Tooltip title="Create Onix imports">
            <Metadatas.Icon />
          </Tooltip>
        </a>
        <a href="/#/metadatas">
          <Tooltip title="Open Onix imports">
            <Filter9PlusIcon />
          </Tooltip>
        </a>
        <a href="/#/source-files">
          <Tooltip title="Show source files">
            <FilesIcon />
          </Tooltip>
        </a>
      </CenterOfAttention>

      <Header color="white" variant="h5">
        <a href="#/">Enrique</a>
      </Header>

      <Spacer />
    </AppBar>
  );
};

export default CustomAppBar;
