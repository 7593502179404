import React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  display: 'inline-block',
  border: '1px solid #aaa',
  width: '152px',
  padding: '15px',
  cursor: 'pointer',
  borderRadius: '10px',
  margin: '10px',
  verticalAlign: 'top',
  height: '172px',
  '&:hover': {
    background: theme.palette.dark.main,
  },
}));

const Div = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '70px',
  '& > svg': {
    fontSize: '70px',
  },
}));

const P = styled('p')(({ theme }) => ({
  fontFamily: 'Karla, sans-serif',
  color: theme.palette.lightgray.main,
}));

const BigActionButton = ({ children, icon, href }) => {
  return (
    <Root href={href}>
      <Div>{icon}</Div>
      <P>{children}</P>
    </Root>
  );
};

export default BigActionButton;
