import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const marks = [-4, -3, -2, -1, 0, 1, 2, 3, 4].map((x) => ({ value: x, label: `${x}s` }));

export default function AudioOffsetSlider({ children, value, onChange }) {
  return (
    <div>
      <Typography id="continuous-slider" gutterBottom sx={{ fontSize: '12px' }}>
        {children}
      </Typography>
      <Grid container>
        <Grid item xs>
          <Slider
            aria-labelledby="continuous-slider"
            marks={marks}
            min={-4}
            max={4}
            step={0.1}
            sx={{
              '& .MuiSlider-markLabel': { fontSize: '10px' },
            }}
            value={value || 0}
            valueLabelDisplay="auto"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
