import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import list from './List';
import create from './Create';
import show from './Show';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list,
  create,
  show,
  Icon: RecordVoiceOverIcon,
};
