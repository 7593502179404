import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';
import { Field, withTypes } from 'react-final-form';

import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import image from '../assets/images/writing-dark.png';

const Main = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
}));
const LoginContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '900px',
  margin: 'auto',
}));
const Image = styled('img')(({ theme }) => ({
  height: '300px',
  transform: 'scaleX(-1)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
const LoginCard = styled(Card)(() => ({
  minWidth: 350,
  border: 'none',
  fontFamily: 'Karla, sans-serif',
}));
const Hint = styled('div')(({ theme }) => ({
  marginTop: '1em',
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.grey[500],
}));
const TypographyHeader = styled(Typography)(() => ({
  textAlign: 'center',
  fontFamily: 'Fredoka One',
}));
const FormContainer = styled('div')(() => ({
  padding: '0 1em 1em 1em',
}));
const InputContainer = styled('div')(() => ({
  marginTop: '1em',
}));
const Actions = styled(CardActions)(() => ({
  padding: '0 1em 1em 1em',
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    variant="outlined"
  />
);

const { Form } = withTypes();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch((error) => {
      setLoading(false);
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        'warning',
        {
          _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
        }
      );
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <>
      <TypographyHeader variant="h3" color="secondary">
        Enrique
      </TypographyHeader>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Main>
              <LoginContainer>
                <Image src={image} alt="Author writing" />
                <LoginCard>
                  <Hint>Sign in with your Boksnok Admin account</Hint>
                  <FormContainer>
                    <InputContainer>
                      <Field
                        autoFocus
                        name="username"
                        component={renderInput}
                        label={translate('ra.auth.username')}
                        disabled={loading}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Field
                        name="password"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading}
                      />
                    </InputContainer>
                  </FormContainer>
                  <Actions>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </Actions>
                </LoginCard>
              </LoginContainer>
              <Notification />
            </Main>
          </form>
        )}
      />
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;
