export default function humanFileSize(bytes) {
  if (bytes < 900) {
    return `${bytes} bytes`;
  } else if (bytes / 1024 < 900) {
    return `${(bytes / 1024).toFixed(1)} KB`;
  } else if (bytes / 1024 / 1024 < 900) {
    return `${(bytes / 1024 / 1024).toFixed(1)} MB`;
  }
  return `${(bytes / 1024 / 1024 / 1024).toFixed(1)} GB`;
}
