import * as React from 'react';
import { Route } from 'react-router-dom';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import Helmet from 'react-helmet';

import authProvider from './authProvider';
import englishMessages from './i18n/en';

import { defaultTheme } from './layout/theme';
import Home from './layout/Home';
import Layout from './layout/Layout';
import Login from './layout/Login';

import metadatas from './resources/metadatas';
import SourceFiles from './resources/sources-files';
import Stats from './resources/stats';
import SystemInfo from './resources/system-info';
import transcriptions from './resources/transcriptions';
import stories from './resources/stories';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    switch (locale) {
      // case 'fr':
      //   return import('./i18n/fr').then((messages) => messages.default);
      default:
        return englishMessages;
    }
  },
  'en',
  { allowMissing: true }
);

const App = ({ dataProvider }) => (
  <Admin
    authProvider={authProvider}
    dashboard={Home}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    layout={Layout}
    loginPage={Login}
    theme={defaultTheme}
  >
    <Helmet title="Enrique - Building stories">
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Fredoka+One&family=Karla:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Resource name="metadatas" {...metadatas} />
    <Resource name="stories" {...stories} />
    <Resource name="transcriptions" {...transcriptions} />
    <CustomRoutes>
      <Route exact path="/system-info" element={<SystemInfo />} />
      <Route exact path="/source-files/:isbn" element={<SourceFiles />} />
      <Route exact path="/source-files" element={<SourceFiles />} />
      <Route exact path="/stats" element={<Stats />} />
    </CustomRoutes>
  </Admin>
);

export default App;
