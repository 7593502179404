import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';

import { getThernSearchBooks } from '../../dataProvider';
import BookList from './BookList';

const PREFIX = 'ThernUploadBookPicker';

const cls = {
  infoText: `${PREFIX}-infoText`,
  createButtonContainer: `${PREFIX}-createButtonContainer`
};

const Root = styled('div')(() => ({
  [`& .${cls.infoText}`]: {
    fontFamily: 'Karla',
    textAlign: 'center',
    width: '100%',
  },

  [`& .${cls.createButtonContainer}`]: {
    marginBottom: '16px',
    textAlign: 'center',
    width: '100%',
  }
}));

export default function ThernUploadBookPicker({
  initialSearch,
  selectedBookId,
  style,
  onCreateNewClicked,
  onSelectedBookId,
}) {

  const [search, setSearch] = React.useState('');
  const [searching, setSearching] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [timerId, setTimerId] = React.useState(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (search) {
      setTimerId(
        setTimeout(() => {
          doSearch(search).then(() => {});
        }, 400)
      );
    }
  }, [search]);

  React.useEffect(() => {
    setSearch(initialSearch);
    doSearch(initialSearch).then(() => {});
  }, [initialSearch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const infoText = searching
    ? 'Searching for: ' + searching
    : results && results.length === 0
    ? 'No books found'
    : null;

  const doSearch = async (searchString) => {
    onSelectedBookId(null);
    setResults(null);
    setSearching(searchString);
    try {
      const searchResult = await getThernSearchBooks(searchString);
      const resultsOfInterest = searchResult.map((b) => ({
        author: b.author,
        bookId: b._id,
        title: b.title,
        published: b.published,
        thumbnail: b.frontcover_small,
      }));
      setResults(resultsOfInterest);
      setSearching(null);
    } catch (ex) {
      console.error('Could not search', ex);
      setResults([]);
      setSearching(null);
    }
  };

  return (
    <Root style={style}>
      <div className={cls.createButtonContainer}>
        <Button variant="outlined" onClick={onCreateNewClicked}>
          Create new book
        </Button>
      </div>

      <DialogContentText>Find book title that this book should be updated with</DialogContentText>

      <TextField
        autoComplete="off"
        autoFocus
        margin="dense"
        id="book-title"
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => doSearch(search)}
              style={{ cursor: 'pointer' }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label="Search book"
        type="text"
        fullWidth
        value={search}
        onChange={(value) => setSearch(value.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && doSearch(search)}
      />
      {infoText && (
        <div className={cls.infoText}>
          <p>{infoText}</p>
          {searching && <CircularProgress />}
        </div>
      )}
      {results && results.length !== 0 && (
        <BookList
          list={results}
          selectedBookId={selectedBookId}
          onSelectedBookId={onSelectedBookId}
        />
      )}
    </Root>
  );
}

ThernUploadBookPicker.propTypes = {
  initialSearch: PropTypes.string,
  selectedBookId: PropTypes.string,
  style: PropTypes.object,
  onCreateNewClicked: PropTypes.func.isRequired,
  onSelectedBookId: PropTypes.func.isRequired,
};
