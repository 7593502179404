import React, { useCallback, useEffect, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';

import Howler from 'howler';
import { Play } from 'rehowl-r18';

function useHowl(howlOptions) {
  const {
    src,
    sprite,
    format,
    html5,
    preload,
    xhrWithCredentials,
    defaultVolume = 0,
  } = howlOptions;
  const [howl, setHowl] = useState(null);
  // Force rerender on load state changes.
  const [, setState] = useState('unloaded');
  // Force rerender on unlock.
  const [, setLocked] = useState(true);

  const [error, setError] = useState(null);

  useEffect(() => {
    const newHowl = new Howler.Howl({
      autoplay: false,
      format,
      html5,
      onload: () => setState('loaded'),
      onloaderror: (id, message) => setError({ id, message }),
      onunlock: () => {
        setLocked(false);
      },
      preload,
      sprite,
      src,
      volume: defaultVolume,
      xhrWithCredentials,
    });
    setHowl(newHowl);

    return () => {
      setHowl(null);
      setState('unloaded');
      setLocked(true);
      setError(null);
      if (!newHowl) return;
      newHowl.off();
      newHowl.stop();
      newHowl.unload();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(src),
    sprite,
    JSON.stringify(format),
    /* eslint-enable react-hooks/exhaustive-deps */
    html5,
    xhrWithCredentials,
    preload,
    defaultVolume,
  ]);

  if (!howl) {
    return {
      howl: null,
      error: null,
      state: 'unloaded',
      load: () => {},
    };
  }
  const state = howl.state();
  return {
    howl: howl,
    error,
    state,
    load:
      state === 'unloaded'
        ? () => {
            howl && howl.load();
            setState('loading');
          }
        : () => {},
  };
}

const PLAY_START = 1;
const PLAY_PAGE = 2;
const PLAY_END = 3;

export default function AudioPreview({
  activateKeyShortcuts,
  audioUrl,
  audioSuffix,
  startTime,
  endTime,
  playbackRate = 1,
}) {
  const [play, setPlay] = useState(false);
  const [digit, setDigit] = useState(1);
  const [sprite] = useState({
    [PLAY_START]: [0, 100],
    [PLAY_PAGE]: [0, 100],
    [PLAY_END]: [0, 100],
  });
  const { howl } = useHowl({
    src: [audioUrl],
    format: audioSuffix ? [audioSuffix] : undefined,
    sprite,
    preload: true,
  });
  useEffect(() => {
    const st = startTime * 1000;
    const et = endTime * 1000;
    sprite[`${PLAY_START}`][0] = st;
    sprite[`${PLAY_START}`][1] = 1750;

    sprite[`${PLAY_PAGE}`][0] = st;
    sprite[`${PLAY_PAGE}`][1] = et - st;

    sprite[`${PLAY_END}`][0] = et - 1750;
    sprite[`${PLAY_END}`][1] = 1750;
  }, [startTime, endTime, sprite]);

  const handleFinishedPlaying = useCallback(() => {
    setPlay(false);
    setDigit(0);
  }, []);

  const playPauseStart = useCallback(() => {
    if (!play) setDigit(PLAY_START);
    setPlay(!play);
  }, [play]);
  const playPausePage = useCallback(() => {
    if (!play) setDigit(PLAY_PAGE);
    setPlay(!play);
  }, [play]);
  const playPauseEnd = useCallback(() => {
    if (!play) setDigit(PLAY_END);
    setPlay(!play);
  }, [play]);

  const captureKeyPress = useCallback(
    (e) => {
      if (!activateKeyShortcuts) return;

      switch (e.code) {
        case 'Comma':
          playPauseStart();
          break;

        case 'Space':
          e.preventDefault();
          e.stopPropagation();
          try {
            playPausePage();
          } catch (_) {}
          break;

        case 'Period':
          playPauseEnd();
          break;

        default:
      }
    },
    [activateKeyShortcuts, playPauseStart, playPausePage, playPauseEnd]
  );
  useEffect(() => {
    document.addEventListener('keydown', captureKeyPress, false);
    return () => {
      document.removeEventListener('keydown', captureKeyPress, false);
    };
  }, [captureKeyPress]);

  return (
    <>
      <Play
        stop={digit === 0 || !play}
        howl={howl}
        rate={playbackRate}
        sprite={digit ? `${digit}` : undefined}
        onEnd={handleFinishedPlaying}
        onStop={handleFinishedPlaying}
        onPause={handleFinishedPlaying}
      />
      <Tooltip variant="light" placement="top" title="[Period] Play first seconds of page audio">
        <button onClick={playPauseStart}>Play start</button>
      </Tooltip>
      <Tooltip variant="light" placement="top" title="[Space] Play whole page audio">
        <button onClick={playPausePage}>Play whole page</button>
      </Tooltip>
      <Tooltip variant="light" placement="top" title="[Comma] Play last seconds of page audio">
        <button onClick={playPauseEnd}>Play end</button>
      </Tooltip>
    </>
  );
}
