import React from 'react';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

import { copy } from '../../utils/copy';

const StyledTextField = styled(TextField)({
  fontFamily: 'Courier New',
  fontSize: '10px',
});

const WeekDiffs = ({ swedish, german }) => {
  if (!swedish) {
    return <div>Come back again next week</div>;
  }

  return (
    <div>
      <form noValidate autoComplete="off">
        <StyledTextField
          aria-readonly={true}
          id="swedish-book-diff"
          label="Swedish book diff"
          multiline
          size="medium"
          variant="outlined"
          value={swedish}
          onChange={() => {}}
        />
        <button onClick={() => copy(swedish)}>Copy</button>
        <br />
        <br />
        <StyledTextField
          aria-readonly={true}
          id="german-book-diff"
          label="German book diff"
          multiline
          size="medium"
          variant="outlined"
          value={german}
          onChange={() => {}}
        />
        <button onClick={() => copy(german)}>Copy</button>
      </form>
    </div>
  );
};

export default WeekDiffs;
