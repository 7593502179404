import React from 'react';

import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PREFIX = 'BookList';

const cls = {
  root: `${PREFIX}-root`,
  imageList: `${PREFIX}-imageList`,
  icon: `${PREFIX}-icon`,
  iconSelected: `${PREFIX}-iconSelected`,
  imageListItemRoot: `${PREFIX}-imageListItemRoot`,
  itembarRoot: `${PREFIX}-itembarRoot`,
  selectedItembarRoot: `${PREFIX}-selectedItembarRoot`,
  stateMarker: `${PREFIX}-stateMarker`,
  publishedState: `${PREFIX}-publishedState`,
  unpublishedState: `${PREFIX}-unpublishedState`,
  actionIcon: `${PREFIX}-actionIcon`,
  subtitle: `${PREFIX}-subtitle`,
  title: `${PREFIX}-title`,
  titleWrap: `${PREFIX}-titleWrap`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${cls.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${cls.imageList}`]: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    margin: 0,
    overflow: 'hidden',
    width: 800,
  },

  [`& .${cls.icon}`]: {
    color: 'rgba(255, 255, 255, 0.54)',
  },

  [`& .${cls.iconSelected}`]: {
    color: 'rgba(20, 102, 220, 0.75)',
  },

  [`& .${cls.imageListItemRoot}`]: {
    cursor: 'pointer',
  },

  [`& .${cls.itembarRoot}`]: {
    height: '56px',
  },

  [`& .${cls.selectedItembarRoot}`]: {
    height: '56px',
    background: 'rgba(5, 50, 255, 0.5)',
  },

  [`& .${cls.stateMarker}`]: {
    borderRadius: '2px',
    fontSize: '7px',
    fontFamily: 'Karla',
    padding: '4px',
    position: 'absolute',
    top: '-2px',
    right: '-2px',
  },

  [`& .${cls.publishedState}`]: {
    backgroundColor: 'limegreen',
  },

  [`& .${cls.unpublishedState}`]: {
    backgroundColor: '#e32636',
    color: 'white',
  },

  [`& .${cls.actionIcon}`]: {
    top: '-50px',
    left: 0,
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
  },

  [`& .${cls.subtitle}`]: {
    fontSize: '7px',
  },

  [`& .${cls.title}`]: {
    fontSize: '8px',
  },

  [`& .${cls.titleWrap}`]: {
    marginLeft: '4px',
    marginRight: '4px',
  },
}));

export default function BookList({ list, selectedBookId, onSelectedBookId }) {
  return (
    <Root className={cls.root}>
      <ImageList cols={6} rowHeight={110} className={cls.imageList}>
        {list.map(({ author, bookId, published, title, thumbnail }) => (
          <ImageListItem
            className={cls.imageListItemRoot}
            key={thumbnail}
            onClick={() => {
              onSelectedBookId(bookId);
            }}
          >
            {thumbnail && <img src={thumbnail} alt={title} />}
            {
              <div
                className={`${cls.stateMarker} ${cls[`${published ? '' : 'un'}publishedState`]}`}
              >
                {published ? 'published' : 'not published'}
              </div>
            }
            <ImageListItemBar
              classes={{
                root: cls[selectedBookId === bookId ? 'selectedItembarRoot' : 'itembarRoot'],
                actionIcon: cls.actionIcon,
                subtitle: cls.subtitle,
                title: cls.title,
                titleWrap: cls.titleWrap,
              }}
              title={title}
              subtitle={author}
              actionIcon={
                <IconButton
                  aria-label={`info about ${title}`}
                  className={selectedBookId === bookId ? cls.iconSelected : cls.icon}
                  onClick={() => {
                    onSelectedBookId(bookId);
                  }}
                  size="large"
                >
                  <CheckCircleIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Root>
  );
}
