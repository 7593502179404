import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
  thernBookIsInQaBookshelf,
  postThernBookInQaBookshelf,
  deleteThernBookInQaBookshelf,
  thernBookIsInTestBookshelf,
  postThernBookInTestBookshelf,
  deleteThernBookInTestBookshelf,
} from '../../../dataProvider/index';

import ThernUploadBookPicker from '../ThernUploadBookPicker';
import ThernUploadMetaData from '../ThernUploadMetaData';
import ThernUploadStatus from '../ThernUploadStatus';

const PREFIX = 'ThernUploadDialog';

const classes = {
  root: `${PREFIX}-root`,
  dialogTitle: `${PREFIX}-dialogTitle`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.root}`]: {
    maxWidth: '550px',
    height: '50vh',
    width: '50vw',
  },

  [`& .${classes.dialogTitle}`]: {
    textAlign: 'center',
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  }
}));

export default function ThernUploadDialog({
  initialMetadata,
  newBookId,
  open,
  initialSearch,
  uploading,
  uploadCompleted,
  uploadError,
  uploadStatus,
  onClose,
  onUploadBookClick,
}) {

  const [selectedBookId, setSelectedBookId] = React.useState(null);
  const [audioEncoding, setAudioEncoding] = React.useState('mp3');
  const [step, setStep] = React.useState(1);
  const [metaData, setMetaData] = React.useState({});
  const [createNewBook, setCreateNewBook] = React.useState(false);
  const [selectedBookMetadata, setSelectedBookMetadata] = React.useState(null);

  const [inTestBookshelf, setInTestBookshelf] = React.useState(null);
  const [testBookshelfChanging, setTestBookshelfChanging] = React.useState(false);
  const [testBookshelfChanged, setTestBookshelfChanged] = React.useState(false);

  const [inQaBookshelf, setInQaBookshelf] = React.useState(null);
  const [qaBookshelfChanging, setQaBookshelfChanging] = React.useState(false);
  const [qaBookshelfChanged, setQaBookshelfChanged] = React.useState(false);

  const bookId = newBookId || selectedBookId;

  React.useEffect(() => {
    const enc = localStorage.getItem('audioEncoding');
    if (enc) {
      setAudioEncoding(enc);
    }
  }, []);

  React.useEffect(() => {
    if (uploading && inTestBookshelf === null) {
      thernBookIsInTestBookshelf(bookId).then((value) => {
        setInTestBookshelf(value);
      });
    }
  }, [bookId, uploading, inTestBookshelf]);

  React.useEffect(() => {
    if (uploading && inQaBookshelf === null) {
      thernBookIsInQaBookshelf(bookId).then((value) => {
        setInQaBookshelf(value);
      });
    }
  }, [bookId, uploading, inQaBookshelf]);

  const getUploadOpts = () => {
    return {
      audioEncoding,
    };
  };

  const handleAudioEncoding = (value) => {
    setAudioEncoding(value);
    localStorage.setItem('audioEncoding', value);
  };
  const handleSelectedBookId = (bookId) => {
    if (bookId === selectedBookId) {
      setSelectedBookId(null);
    } else {
      setSelectedBookId(bookId);
    }
  };
  const handleMetaChange = useCallback((obj) => {
    setMetaData(
      (oldMeta) =>
        console.log('Updating meta', {
          ...oldMeta,
          ...obj,
        }) || {
          ...oldMeta,
          ...obj,
        }
    );
  }, [setMetaData]);
  const handleCreateNewClicked = () => {
    setCreateNewBook(true);
    setStep(2);
  };

  const handleAddToTestBookshelf = () => {
    setTestBookshelfChanging(true);
    postThernBookInTestBookshelf(bookId).then(() => {
      setTestBookshelfChanged(true);
      setTestBookshelfChanging(false);
      setTimeout(() => {
        setInTestBookshelf(true);
        setTestBookshelfChanged(false);
      }, 2000);
    });
  };
  const handleRemoveFromTestBookshelf = () => {
    setTestBookshelfChanging(true);
    deleteThernBookInTestBookshelf(bookId).then(() => {
      setTestBookshelfChanged(true);
      setTestBookshelfChanging(false);
      setTimeout(() => {
        setInTestBookshelf(false);
        setTestBookshelfChanged(false);
      }, 2000);
    });
  };
  const handleAddToQaBookshelf = () => {
    setQaBookshelfChanging(true);
    postThernBookInQaBookshelf(bookId).then(() => {
      setQaBookshelfChanged(true);
      setQaBookshelfChanging(false);
      setTimeout(() => {
        setInQaBookshelf(true);
        setQaBookshelfChanged(false);
      }, 2000);
    });
  };
  const handleRemoveFromQaBookshelf = () => {
    setQaBookshelfChanging(true);
    deleteThernBookInQaBookshelf(bookId).then(() => {
      setQaBookshelfChanged(true);
      setQaBookshelfChanging(false);
      setTimeout(() => {
        setInQaBookshelf(false);
        setQaBookshelfChanged(false);
      }, 2000);
    });
  };

  const handleMetaDataBookLoaded = useCallback((metadata) => {
    setSelectedBookMetadata(metadata);
  }, []);

  const title = uploading
    ? uploadError
      ? 'Failed to upload Epub'
      : uploadCompleted
      ? 'Epub uploaded'
      : 'Epub is being created...'
    : 'Book upload';

  return (
    <StyledDialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.root}>
        {uploading && (
          <ThernUploadStatus
            bookId={bookId}
            completed={uploadCompleted}
            error={uploadError}
            inQaBookshelf={inQaBookshelf}
            inTestBookshelf={inTestBookshelf}
            status={uploadStatus}
            qaShelfChanging={qaBookshelfChanging}
            qaShelfChanged={qaBookshelfChanged}
            testShelfChanging={testBookshelfChanging}
            testShelfChanged={testBookshelfChanged}
            onAddToQaBookshelf={handleAddToQaBookshelf}
            onAddToTestBookshelf={handleAddToTestBookshelf}
            onRemoveFromQaBookshelf={handleRemoveFromQaBookshelf}
            onRemoveFromTestBookshelf={handleRemoveFromTestBookshelf}
          />
        )}
        {!uploading && (
          <>
            <ThernUploadBookPicker
              initialSearch={initialSearch}
              selectedBookId={selectedBookId}
              style={{ display: step === 2 ? 'none' : null }}
              onCreateNewClicked={handleCreateNewClicked}
              onSelectedBookId={handleSelectedBookId}
            />

            <ThernUploadMetaData
              audioEncoding={audioEncoding}
              initialMetadata={initialMetadata}
              selectedBookId={createNewBook ? null : selectedBookId}
              style={{ display: step === 1 ? 'none' : null }}
              onAudioEncodingChange={handleAudioEncoding}
              onBookLoaded={handleMetaDataBookLoaded}
              onMetaChange={handleMetaChange}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {uploadError || uploadCompleted ? 'Close' : 'Cancel'}
        </Button>

        <Button
          color="primary"
          disabled={uploading}
          style={{ display: step === 1 || uploadError || uploadCompleted ? 'none' : null }}
          onClick={() => {
            setStep(1);
            setCreateNewBook(false);
          }}
        >
          Prev
        </Button>

        <Button
          color="primary"
          disabled={!selectedBookId || uploading}
          style={{ display: step === 2 || uploadError || uploadCompleted ? 'none' : null }}
          onClick={() => setStep(2)}
        >
          Next
        </Button>

        <Button
          color="primary"
          disabled={(!selectedBookId && !createNewBook) || uploading}
          style={{ display: step === 1 || uploadError || uploadCompleted ? 'none' : null }}
          onClick={() =>
            onUploadBookClick(selectedBookId, metaData, selectedBookMetadata, getUploadOpts())
          }
        >
          {createNewBook ? 'Create & Upload' : 'Upload'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

ThernUploadDialog.propTypes = {
  open: PropTypes.bool,
  initialSearch: PropTypes.string,
  completed: PropTypes.bool,
  error: PropTypes.string,
  status: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
