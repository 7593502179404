export const AGE_GROUPS = [
  { value: '0-3', label: '0-3' },
  { value: '3-6', label: '3-6' },
  { value: '6-9', label: '6-9' },
];

export const PLAYBACK_MODES = Object.freeze({ QUICK: 'QUICK', FULL: 'FULL' });
export const PLAYBACK_MODES_VALUES = Object.freeze(Object.values(PLAYBACK_MODES));
export const MODES = Object.freeze({
  DEFAULT: 'DEFAULT',
  EXCLUDE: 'EXCLUDE',
  SPLIT: 'SPLIT',
  CROP: 'CROP',
});
export const STORE = Object.freeze({
  STORY_PAGE: 'story.page',
  ASSET_LIST_OPENED_PREFIX: 'asset-list.opened.'
});
