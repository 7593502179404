import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PasteDialog({
  open,
  acceptLabel = 'Go',
  description = '',
  title = 'Paste',
  onAccept,
  onClose,
}) {
  const [text, setText] = useState('');
  const handleAccept = () => onAccept(text);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          fullWidth
          id="name"
          label="Paste from Clipboard"
          margin="dense"
          minRows={3}
          multiline
          type="textArea"
          value={text}
          variant="outlined"
          onChange={({ target }) => setText(target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAccept} color="primary">
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
