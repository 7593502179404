import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useRecordContext, useRefresh } from 'react-admin';

import BookPreviewField from './BookPreviewField';
import Api from '../../dataProvider';
import sleep from '../../utils/sleep';
import slacker from '../../dataProvider/slacker';

const ProgressBoxDiv = styled('div')({
  fontFamily: 'Karla, sans-serif',
  fontWeight: 400,
  margin: '50px auto',
  width: 400,
  border: '1px solid #eee',
  padding: 20,
  borderRadius: '8px',
  boxShadow: '2px 2px 5px 0 #ccc',
});
const RECOVER_CONTAINER_STYLE = {
  fontFamily: 'Fredoka One',
  textAlign: 'center',
};

const { getOneStatus, updatePatch } = Api();

async function awaitForCompletedStatus(uploadId, statusUpdateFunc) {
  let processing = true;
  let released = false;
  let status;

  while (processing && !released) {
    await sleep(750);
    ({ processing, status } = await getOneStatus(uploadId));
    released = statusUpdateFunc(status);
  }
  return !processing;
}

const BookShowField = (props) => {
  const refresh = useRefresh();
  const { id: uploadId, error, processing, status, title } = useRecordContext(props);
  const [updatedStatus, setUpdatedStatus] = React.useState(status);

  React.useEffect(() => {
    let released = false;
    if (processing) {
      awaitForCompletedStatus(uploadId, (newStatus) => {
        setUpdatedStatus(newStatus);
        return released;
      }).then(async (processed) => {
        if (processed) {
          await slacker('Story created', { storyId: uploadId, title });

          refresh();
        }
      });
    }
    return () => {
      released = true;
    };
  }, [processing, refresh, title, uploadId]);

  const handleRecoverError = () => {
    updatePatch(uploadId, { error: null }).then(() => {
      refresh();
    });
  };

  if (error) {
    return (
      <ProgressBoxDiv>
        <h3 style={{ color: '#b21642' }}>There was a problem processing the story...</h3>
        <p>{error}</p>
        <div style={RECOVER_CONTAINER_STYLE}>
          <p>Try to:</p>
          <Button type="button" variant="outlined" onClick={handleRecoverError}>
            Recover from error
          </Button>
        </div>
      </ProgressBoxDiv>
    );
  }

  if (processing) {
    return (
      <ProgressBoxDiv>
        <h2>Story is being processed...</h2>
        <h3>Status</h3>
        <div>{updatedStatus}</div>
      </ProgressBoxDiv>
    );
  }

  return <BookPreviewField {...props} />;
};

BookShowField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export default BookShowField;
