import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useNotify } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import Base from './Base';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.secondary.main,
}));
const Title = styled(Typography)(({ theme }) => ({
  paddingTop: 0,
  fontFamily: 'Fredoka One',
  marginLeft: theme.spacing(2),
  flex: 1,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AudioCuttingDialog({ open, onClose, onCutAudio }) {
  const notify = useNotify();

  const [cutParams, setCutParams] = useState(false);
  const commandAsText = useMemo(() => {
    if (Array.isArray(cutParams)) {
      return cutParams
        .map((p) => [p.page, Number(p.from).toFixed(2), Number(p.to).toFixed(2)].join(','))
        .join('|');
    }
    return '';
  }, [cutParams]);

  const handleCopyClicked = useCallback(() => {
    notify('✂️ Cutting command copied to clipboard');
  }, [notify]);
  const handleCutClick = useCallback(() => {
    onCutAudio(cutParams);
  }, [cutParams, onCutAudio]);

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <StyledAppBar>
        <Toolbar sx={{ color: 'black', minHeight: '32px !important' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Title variant="h6">Sound</Title>
          <CopyToClipboard text={commandAsText} onCopy={handleCopyClicked}>
            <Button disabled={!cutParams} sx={{ color: 'white !important' }}>
              Copy cut command
            </Button>
          </CopyToClipboard>
          <Button disabled={!cutParams} color="inherit" onClick={handleCutClick}>
            Cut audio
          </Button>
          <Button autoFocus color="inherit" onClick={onClose}>
            Cancel
          </Button>
        </Toolbar>
      </StyledAppBar>
      <Base open={open} onCutParameterChange={setCutParams} />
    </Dialog>
  );
}

AudioCuttingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCutAudio: PropTypes.func.isRequired,
};
