export const copy = (plainText) => {
  const htmlText = `<table>${plainText.split('\n').map(x => `<tr><td>${x}</td></tr>`)}</table>`
  function listener(e) {
    e.clipboardData.setData("text/html", htmlText);
    e.clipboardData.setData("text/plain", plainText);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);

  return false;
};
