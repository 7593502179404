import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import { MODES } from '../../constants';
import CropTool from './CropTool';
import PageView from './PageView';

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.dark.main,
  textAlign: 'center',
  padding: 15,
}));

const Spread = ({
  className,
  leftPageNumber,
  rightPageNumber,
  playbackPage,
  isPlaying,
  isPaused,
  playbackMode,
  playbackRate,
  pages,
  handlePlaybackCompleted,
  id,
  ts,
  onRefresh,
  mode,
  setCropState,
}) => {
  const leftPageRef = useRef();
  const rightPageRef = useRef();

  const [offsetCenter, setOffsetCenter] = useState(0);

  if (!pages[leftPageNumber]) {
    return <Root />;
  }

  return (
    <Root className={className}>
      <PageView
        audio={pages[leftPageNumber].audio}
        backgroundImage={pages[leftPageNumber].backgroundImage}
        droppable={mode !== MODES.CROP}
        id={id}
        isCover={leftPageNumber === 0}
        key={`page-${leftPageNumber}`}
        offsetCenter={offsetCenter}
        page={leftPageNumber}
        playbackMode={playbackMode}
        playbackPaused={isPaused}
        playbackRate={playbackRate}
        ref={leftPageRef}
        startPlayback={leftPageNumber === playbackPage && isPlaying}
        ts={ts}
        onPlaybackCompleted={handlePlaybackCompleted}
        onRefresh={onRefresh}
      />
      {rightPageNumber && (
        <PageView
          audio={pages[rightPageNumber].audio}
          backgroundImage={pages[rightPageNumber].backgroundImage}
          droppable={mode !== MODES.CROP}
          id={id}
          isRight
          key={`page-${rightPageNumber}`}
          offsetCenter={offsetCenter}
          page={rightPageNumber}
          playbackMode={playbackMode}
          playbackPaused={isPaused}
          playbackRate={playbackRate}
          ref={rightPageRef}
          startPlayback={rightPageNumber === playbackPage && isPlaying}
          ts={ts}
          onPlaybackCompleted={handlePlaybackCompleted}
          onRefresh={onRefresh}
        />
      )}
      <CropTool
        isCover={leftPageNumber === 0}
        leftPage={leftPageNumber}
        rightPage={rightPageNumber}
        leftPageRef={leftPageRef}
        rightPageRef={rightPageRef}
        offsetCenter={offsetCenter}
        setOffsetCenter={setOffsetCenter}
        setCropState={setCropState}
        mode={mode}
      />
    </Root>
  );
};

Spread.propTypes = {
  leftPage: PropTypes.number,
  rightPage: PropTypes.number,
  playbackPage: PropTypes.number,
  isPlaying: PropTypes.bool,
  isPaused: PropTypes.bool,
  playbackMode: PropTypes.string,
  pages: PropTypes.array,
  handlePlaybackCompleted: PropTypes.func,
  id: PropTypes.number,
  onRefresh: PropTypes.func,
  mode: PropTypes.string,
  setCropState: PropTypes.func,
};

export default Spread;
