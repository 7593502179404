export function extractAudioCutDetailsFromCommand(command) {
  const regex = /"(\d+,[\d.]+,[\d.]+(\|\d+,[\d.]+,[\d.]+)*)"/;
  const pattern = command.match(regex);

  if (!pattern) return null;

  return pattern[1]
    .split('|')
    .map((part) => part.split(',').map(Number))
    .map(([page, from, to]) => ({ page, from, to }));
}
