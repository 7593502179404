import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PagePreviewSmall from './PagePreviewSmall';

const PREFIX = 'PagesOverview';

const cls = {
  root: `${PREFIX}-root`,
  pagesText: `${PREFIX}-pagesText`,
};

const Root = styled('div')({
  [`&.${cls.root}`]: {
    background: '#333',
    overflowY: 'scroll',
  },
  [`& .${cls.pagesText}`]: {
    marginTop: 24,
    textAlign: 'center',
  },
});

const PagesOverview = ({
  activePages,
  className,
  pages,
  selectable,
  selectedPages,
  ts,
  onPageClick,
  onPageSelect,
}) => {
  return (
    <Root className={`${className} ${cls.root}`}>
      <Typography className={cls.pagesText}>Pages</Typography>
      {pages.map(({ backgroundImage, audio }, pageNumber) => (
        <PagePreviewSmall
          active={pageNumber === activePages[0] || pageNumber === activePages[1]}
          backgroundImage={backgroundImage}
          hasAudio={!!audio}
          isCover={pageNumber === 0}
          isSelected={selectedPages[pageNumber]}
          key={`${pageNumber}`}
          page={pageNumber}
          selectable={selectable}
          ts={ts}
          onClick={onPageClick}
          onSelect={onPageSelect}
        />
      ))}
    </Root>
  );
};

PagesOverview.propTypes = {
  activePages: PropTypes.array,
  pages: PropTypes.array,
  selectable: PropTypes.bool,
  selectedPages: PropTypes.array,
  onPageClick: PropTypes.func,
  onPageSelect: PropTypes.func,
};

export default PagesOverview;
