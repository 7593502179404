import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Title } from 'react-admin';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import FileList from './FileList';
import Search from './Search';

const StyledHeader = styled('div')({
  display: 'block',
  fontWeight: 'bold',
  fontSize: '22px',
  margin: '1em 0',
  width: '100%',
});

const SourceFiles = () => {
  const navigate = useNavigate();

  const { isbn } = useParams();

  const handleSearch = (isbn) => {
    navigate(`/source-files/${isbn}`);
  };

  return (
    <Card sx={{ overflowY: 'auto' }}>
      <Title title="Source files" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={0} sm={3} md={3} alignItems="flex-start" />
          <Grid container item xs={12} sm={6} md={6} alignItems="flex-start">
            <div>
              {isbn && (
                <>
                  <StyledHeader>Files for {isbn}</StyledHeader>
                  <FileList isbn={isbn} />
                  <br />
                  <br />
                </>
              )}
              <Search onSearch={handleSearch} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SourceFiles;
