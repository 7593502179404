import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecordContext } from 'react-admin';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import MUIPublishIcon from '@mui/icons-material/Publish';

import { AUDIO_MIME_TYPE_LIST, IMAGE_MIME_TYPE_LIST } from '../../utils/mimeTypes';
import Api from '../../dataProvider/index';

import { Folder } from './assets';

const PREFIX = 'AssetsList';

const cls = {
  assets: `${PREFIX}-assets`,
  internalAssets: `${PREFIX}-internalAssets`,
  upload: `${PREFIX}-upload`,
};

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  padding: 0,
  overflowY: 'scroll',
  fontSize: '12px',

  [`&.drop-zone`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: '4px dashed gray',
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${cls.internalAssets}`]: {
    position: 'relative',
    padding: '10px',
    margin: 0,
  },

  [`& .${cls.upload}`]: {
    float: 'right',
    marginTop: '14px',
    marginRight: '-4px',
    width: '22px',
    cursor: 'pointer',
  },
}));

const acceptedMimeTypes = [...AUDIO_MIME_TYPE_LIST, ...IMAGE_MIME_TYPE_LIST].join(',');

const { uploadFiles } = Api();

function getFiles(dataTransfer, onlyCheck) {
  let files = [];
  for (let i = 0; i < dataTransfer.items.length; i++) {
    if (dataTransfer.items[i].kind === 'file') {
      if (onlyCheck) return true;
      files.push(dataTransfer.items[i].getAsFile());
    }
  }
  return files.length ? files : null;
}

const AssetsList = ({ className, files, onRefresh, onUpdatePreviewUrl }) => {
  const { id } = useRecordContext();
  const [showUploading, setShowUploading] = useState(false);
  const [showDropZone, setShowDropZone] = useState(0);

  const pdfFiles = files.filter((f) => f.type === 'pdf');
  const imageFiles = files.filter((f) => f.type === 'image');
  const audioFiles = files.filter((f) => f.type === 'audio');
  const metadataFiles = files.filter((f) => ['xml', 'json'].includes(f.type));
  const fileUploadRef = useRef();

  const handleUploadFiles = async (event) => {
    const files = Array.from(event.target.files);
    await uploadFiles(id, files);
    onRefresh();
  };

  const handleUploadClick = () => fileUploadRef.current.click();

  const dropHandler = async (e) => {
    e.preventDefault();

    const files = getFiles(e.dataTransfer);

    if (files) {
      setShowUploading(true);
      await uploadFiles(id, files);
      onRefresh();
      setShowUploading(false);
      setShowDropZone(0);
    } else {
      setShowDropZone(0);
    }
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    if (getFiles(e.dataTransfer, true)) {
      setShowDropZone((x) => x + 1);
    }
  };
  const dragLeft = (e) => {
    e.preventDefault();
    if (getFiles(e.dataTransfer, true)) {
      setShowDropZone((x) => x - 1);
    }
  };

  return (
    <Root
      className={`${className} ${cls.assets} ${showDropZone ? 'drop-zone' : ''}`}
      onDrop={dropHandler}
      onDragEnter={dragEnter}
      onDragLeave={dragLeft}
      onDragOver={dragOverHandler}
    >
      <div
        className={cls.internalAssets}
        style={showDropZone ? { display: 'none', pointerEvents: 'none' } : {}}
      >
        <List
          sx={{ width: '100%', bgcolor: 'background.default' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{ bgcolor: 'background.default' }}
            >
              Files
              <MUIPublishIcon className={cls.upload} onClick={handleUploadClick} />
            </ListSubheader>
          }
        >
          <Folder fileList={pdfFiles} label="Sources" />
          <Folder fileList={imageFiles} label="Images" onUpdatePreviewUrl={onUpdatePreviewUrl} />
          <Folder fileList={audioFiles} label="Audio files" />
          <Folder fileList={metadataFiles} label="Metadata" />
        </List>
        <input
          accept={acceptedMimeTypes}
          hidden
          multiple
          name="file"
          ref={fileUploadRef}
          type="file"
          onChange={handleUploadFiles}
        />
      </div>
      <div style={{ display: showDropZone ? undefined : 'none', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          {showUploading ? 'Uploading files...' : 'Drop file(s) here'}
        </Typography>
        <p>Accepts: image, pdf, json, xml</p>
      </div>
    </Root>
  );
};

AssetsList.propTypes = {
  files: PropTypes.array,
};

export default AssetsList;
