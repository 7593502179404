import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { throttle } from 'lodash';

import { styled } from '@mui/material/styles';

import { MODES } from '../../constants';

const DragPoint = styled('div')({
  width: '8px',
  height: '8px',
  background: '#fff',
  border: '1px solid black',
  position: 'absolute',
  boxSizing: 'border-box',
});
const Overlay = styled('div')({
  background: 'transparent',
  borderColor: 'rgba(0,0,0,0.5)',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  height: '100%',
  left: '0',
  position: 'absolute',
  top: '0',
  width: '100%',
});

const CropTool = ({
  isCover,
  leftPageRef,
  rightPageRef,
  leftPage,
  rightPage,
  offsetCenter,
  setOffsetCenter,
  mode,
  setCropState,
}) => {
  const [minW, setMinW] = useState(15);
  const [minN] = useState(55);
  const [minE, setMinE] = useState(15);
  const [minS, setMinS] = useState(175);
  const lockedX = true;

  const [pageCenter, setPageCenter] = useState(400);
  const [pageHeight, setPageHeight] = useState(500);

  const cropRef = useRef();

  const [x1, setX1] = useState(0);
  const [x2, setX2] = useState(0);
  const [y1, setY1] = useState(0);
  const [y2, setY2] = useState(0);

  const [offsetCenterStart, setOffsetCenterStart] = useState(0);
  const [centerPointHidden, setCenterPointHidden] = useState(false);

  const [xStart, setXStart] = useState(0);
  const [a, setA] = useState(0);
  const [yStart, setYStart] = useState(0);
  const [b, setB] = useState(0);

  const [done, setDone] = useState(0);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const leftBox = leftPageRef.current.getBoundingClientRect();
    const fullWidth = leftPageRef.current.naturalWidth;
    const ratio = fullWidth / leftBox.width;

    const cropX = Math.round((x1 - minW - offsetCenter) * ratio);
    const cropY = Math.round((y1 - minN) * ratio);
    const cropWidth = Math.round((leftBox.width - (x1 - minW)) * ratio);
    const cropHeight = Math.round((leftBox.height - (y1 - minN) - (y2 - minS)) * ratio);
    const rightX = Math.round(offsetCenter * ratio);
    const coverX = Math.round((fullWidth - cropWidth) / 2);

    setCropState([cropX, cropY, cropWidth, cropHeight, rightX, coverX]);
  }, [done]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (cropRef.current && leftPageRef) {
      setCropState(null);

      const markDetectorReducer = ({ marks = [], currentStart = -1 } = {}, value, x) => {
        // Find the start of a new marker
        if (currentStart === -1 && value < 253) {
          currentStart = x;
          // else: Find end of current marker
        } else if (currentStart >= 0 && value >= 253) {
          marks.push((currentStart + x) / 2);
          currentStart = -1;
        }
        return { marks, currentStart };
      };
      const getCropMarks = async (page) => {
        const img = document.getElementById(`page-image-${page}`);
        const canvas = document.createElement('canvas');

        // To prevent cross site domain error stuff Todo: Test on enrique.boksnok.se
        img.crossOrigin = 'Anonymous';

        await new Promise((res) => {
          const isLoaded = img.complete && img.naturalHeight !== 0;
          if (isLoaded) return res();
          img.onload = res;
        });

        const context2d = canvas.getContext('2d');
        const rgbOffset = { red: 0, green: 1, blue: 2 }.red;

        canvas.width = img.width;
        canvas.height = img.height;
        context2d.drawImage(img, 0, 0, img.width, img.height);

        const redXValues = context2d
          .getImageData(0, 4, canvas.width, 1)
          .data.filter((_, i) => !((i - rgbOffset) % 4));
        const redYValues = context2d
          .getImageData(4, 0, 1, canvas.height)
          .data.filter((_, i) => !((i - rgbOffset) % 4));

        const { marks: marksX } = redXValues.reduce(markDetectorReducer, {});
        const { marks: marksY } = redYValues.reduce(markDetectorReducer, {});
        const hasMarks = marksX.length === 2 && marksY.length === 2;

        return {
          left: hasMarks ? marksX[0] : 0,
          right: hasMarks ? img.width - marksX[1] : 0,
          top: hasMarks ? marksY[0] : 0,
          bottom: hasMarks ? img.height - marksY[1] : 0,
        };
      };

      const cropBox = cropRef.current.getBoundingClientRect();
      const leftBox = leftPageRef.current.getBoundingClientRect();

      setPageHeight(leftBox.height);
      setPageCenter(cropBox.width / 2);

      let boundSouth = cropBox.height - leftBox.height - minN;
      let boundEast, boundWest;

      if (isCover) {
        boundWest = Math.ceil(cropBox.width / 2) - Math.ceil(leftBox.width / 2);
        boundEast = boundWest;
      } else {
        boundWest = Math.ceil(cropBox.width / 2) - leftBox.width;
        if (rightPageRef && rightPageRef.current) {
          boundEast =
            Math.ceil(cropBox.width / 2) - rightPageRef.current.getBoundingClientRect().width;
        } else {
          boundEast = boundWest + leftBox.width;
        }
      }

      setMinS(boundSouth);
      setMinW(boundWest);
      setMinE(boundEast);

      setX1(boundWest);
      setX2(boundEast);
      setY1(minN);
      setY2(boundSouth);

      if (!isCover) {
        Promise.all([getCropMarks(leftPage), getCropMarks(rightPage)]).then(
          ([leftCropMark, rightCropMark]) => {
            if (leftCropMark && rightCropMark) {
              const centerOffset = leftCropMark.right;
              setOffsetCenter(centerOffset);
              setX1(boundWest + leftCropMark.left + centerOffset);
              setX2(boundEast + rightCropMark.right + centerOffset);
              setY1(minN + leftCropMark.top);
              setY2(boundSouth + leftCropMark.bottom);
              setDone(done + 1);
            }
          }
        );
      }
    } else {
      setOffsetCenter(0);
    }
  }, [mode]);

  const startOffsetCenter = (e) => {
    setOffsetCenterStart(e.clientX);
    setCenterPointHidden(true);
  };

  const onOffsetCenter = (e) => {
    const scale = 10;
    const value = Math.max(Math.abs(offsetCenterStart - e.clientX) / scale, 0);
    setOffsetCenter(value);
  };

  const endOffsetCenter = (e) => {
    onOffsetCenter(e);
    setCenterPointHidden(false);
    setDone(done + 1);
  };

  const startResize = (arr, clientX, clientY) => {
    setA(clientX);
    setB(clientY);
    arr.forEach((d) => {
      switch (d) {
        case 'W':
          setXStart(x1);
          break;
        case 'N':
          setYStart(y1);
          break;
        case 'E':
          setXStart(x2);
          break;
        case 'S':
          setYStart(y2);
          break;
        default:
      }
    });
  };

  const resize = (arr, clientX, clientY) => {
    const diffX = clientX - a;
    const diffY = clientY - b;
    arr.forEach((d) => {
      switch (d) {
        case 'W':
          setX1(Math.min(Math.max(minW + offsetCenter, xStart + diffX), pageCenter - 20));
          if (lockedX) {
            setX2(Math.min(Math.max(minE + offsetCenter, xStart + diffX), pageCenter - 20));
          }
          break;
        case 'N':
          setY1(Math.min(Math.max(minN, yStart + diffY), pageHeight + minN + minS - y2 - 20));
          break;
        case 'E':
          setX2(Math.min(Math.max(minE + offsetCenter, xStart - diffX), pageCenter - 20));
          if (lockedX) {
            setX1(Math.min(Math.max(minW + offsetCenter, xStart - diffX), pageCenter - 20));
          }
          break;
        case 'S':
          setY2(Math.min(Math.max(minS, yStart - diffY), pageHeight + minN + minS - y1 - 20));
          break;
        default:
      }
    });
  };

  const onResize = useMemo(() => throttle(resize, 30), [a, b]);

  const endResize = (arr, clientX, clientY) => {
    onResize(arr, clientX, clientY);
    setDone(done + 1);
  };

  return (
    <>
      {mode === MODES.CROP && (
        <>
          <Overlay
            style={{
              borderTopWidth: y1 + 'px',
              borderRightWidth: x2 + 'px',
              borderLeftWidth: x1 + 'px',
              borderBottomWidth: y2 + 'px',
            }}
            ref={cropRef}
          />

          <DragPoint
            id="dragPointW"
            style={{
              top: (minN + pageHeight + minS - y1 - y2) / 2 - 4 + y1 + 'px',
              left: x1 - 8 + 'px',
              cursor: 'ew-resize',
            }}
            draggable="true"
            onDragStart={(e) => startResize(['W'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['W'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['W'], e.clientX, e.clientY)}
          />

          <DragPoint
            id="dragPointE"
            style={{
              top: (minN + pageHeight + minS - y1 - y2) / 2 - 4 + y1 + 'px',
              right: x2 - 8 + 'px',
              cursor: 'ew-resize',
            }}
            draggable="true"
            onDragStart={(e) => startResize(['E'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['E'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['E'], e.clientX, e.clientY)}
          />

          <DragPoint
            id="dragPointN"
            style={{
              top: y1 - 8 + 'px',
              // left: ((x2 + x1) /  2 - 4) + 'px',
              left: 'calc(50% - 4px)',
              cursor: 'ns-resize',
            }}
            draggable="true"
            onDragStart={(e) => startResize(['N'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['N'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['N'], e.clientX, e.clientY)}
          />

          <DragPoint
            id="dragPointS"
            style={{
              bottom: y2 - 8 + 'px',
              left: 'calc(50% - 4px)',
              cursor: 'ns-resize',
            }}
            draggable="true"
            onDragStart={(e) => startResize(['S'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['S'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['S'], e.clientX, e.clientY)}
          />

          <DragPoint
            id="dragPointNW"
            style={{ top: y1 - 8 + 'px', left: x1 - 8 + 'px', cursor: 'nwse-resize' }}
            draggable="true"
            onDragStart={(e) => startResize(['W', 'N'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['W', 'N'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['W', 'N'], e.clientX, e.clientY)}
          />
          <DragPoint
            id="dragPointNE"
            style={{ top: y1 - 8 + 'px', right: x2 - 8 + 'px', cursor: 'nesw-resize' }}
            draggable="true"
            onDragStart={(e) => startResize(['E', 'N'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['E', 'N'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['E', 'N'], e.clientX, e.clientY)}
          />
          <DragPoint
            id="dragPointSE"
            style={{ bottom: y2 - 8 + 'px', right: x2 - 8 + 'px', cursor: 'nwse-resize' }}
            draggable="true"
            onDragStart={(e) => startResize(['E', 'S'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['E', 'S'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['E', 'S'], e.clientX, e.clientY)}
          />
          <DragPoint
            id="dragPointSW"
            style={{ bottom: y2 - 8 + 'px', left: x1 - 8 + 'px', cursor: 'nesw-resize' }}
            draggable="true"
            onDragStart={(e) => startResize(['W', 'S'], e.clientX, e.clientY)}
            onDrag={(e) => onResize(['W', 'S'], e.clientX, e.clientY)}
            onDragEnd={(e) => endResize(['W', 'S'], e.clientX, e.clientY)}
          />

          {!isCover && (
            <DragPoint
              style={{
                opacity: centerPointHidden ? 0.5 : 1.0,
                marginLeft: '-5px',
                top: (minN + pageHeight + minS - y1 - y2) / 2 - 4 + y1 + 'px',
                left: '50%',
                cursor: 'col-resize',
                transform: 'rotate(45deg)',
              }}
              draggable="true"
              onDragStart={startOffsetCenter}
              onDrag={onOffsetCenter}
              onDragEnd={endOffsetCenter}
            />
          )}
        </>
      )}
    </>
  );
};

CropTool.propTypes = {
  isCover: PropTypes.bool,
  leftPageRef: PropTypes.object,
  rightPageRed: PropTypes.object,
  leftPage: PropTypes.number,
  rightPage: PropTypes.number,
  mode: PropTypes.string,
  setCropState: PropTypes.func,
};

export default CropTool;
