import { BASE_URL } from '../config';
import {
  AUDIO_MIME_TYPE_LIST,
  IMAGE_MIME_TYPE_LIST,
  METADATA_MIME_TYPES,
} from '../utils/mimeTypes';

import { catchIt, extractFilesToUpload, getHeadersWithAuth, parseResponse } from './helpers';
import slacker from './slacker';

async function create(resource, params) {
  switch (resource) {
    case 'stories':
      break;
    case 'metadatas':
      return createMetadatas(resource, params);
    case 'transcriptions':
      return createTranscriptions(resource, params);
    default:
      throw new Error(`Cannot handle resource '${resource}'`);
  }

  const { files, ...dataFields } = params.data || {};
  const form = new FormData();

  // throw new Error('Stop now');
  Object.keys(dataFields).forEach((name) => {
    form.append(name, dataFields[name]);
  });

  if (!dataFields.thernIsbn) {
    const { epub, pdfs, audioFiles, imageFiles, jsonFiles, xmlFiles } = extractFilesToUpload(files);

    form.append('epub', epub);
    pdfs.forEach((pdf) => {
      form.append('pdfs', pdf);
    });
    audioFiles.forEach((file) => {
      form.append('audioFiles', file);
    });
    imageFiles.forEach((file) => {
      form.append('imageFiles', file);
    });
    xmlFiles.forEach((file) => {
      form.append('xmlFiles', file);
    });
    jsonFiles.forEach((file) => {
      form.append('jsonFiles', file);
    });
  }

  return await fetch(`${BASE_URL}/stories`, {
    method: 'POST',
    body: form,
    ...getHeadersWithAuth(),
  })
    .then(parseResponse)
    .then((json) => {
      const { id, title } = json.data;

      slacker('Creating story', { storyId: id, title });

      return json;
    });
}

async function createMetadatas(resource, params) {
  const { files, ...dataFields } = params.data || {};
  const form = new FormData();
  const xmlFiles = (files || []).map(({ rawFile }) => rawFile);

  Object.keys(dataFields).forEach((name) => {
    form.append(name, dataFields[name]);
  });
  xmlFiles.forEach((file) => {
    form.append('xmlFiles', file);
  });

  return await fetch(`${BASE_URL}/${resource}`, {
    method: 'POST',
    body: form,
    ...getHeadersWithAuth(),
  }).then(parseResponse);
}

async function createTranscriptions(resource, params) {
  const { files, ...dataFields } = params.data || {};
  const form = new FormData();
  const audioFiles = (files || []).map(({ rawFile }) => rawFile);

  Object.keys(dataFields).forEach((name) => {
    form.append(name, dataFields[name]);
  });
  audioFiles.forEach((file) => {
    form.append('files', file);
  });

  return await fetch(`${BASE_URL}/transcriptions`, {
    method: 'POST',
    body: form,
    ...getHeadersWithAuth(),
  })
    .then(parseResponse)
    .then((json) => ({ ...json, multi: true }));
}

/***
 *
 * @param resourceId
 * @param actionFunc
 * @param actionParams
 * @param onStatusUpdate
 * @return {Promise<*>}
 */
async function executeActionAndWait(resourceId, actionFunc, actionParams, onStatusUpdate) {
  async function checkStatus() {
    const {
      data: { error, bookId, completed, status },
    } = await getOne('stories', { id: resourceId });

    onStatusUpdate(error || null, { bookId, completed, status });
    if (!(error || completed)) {
      setTimeout(checkStatus, 350);
    }
  }
  let lastStatus = null;

  return await actionFunc(resourceId, ...actionParams).then(
    async ({ error = null, completed = false, status = '' } = {}) => {
      setTimeout(checkStatus, 500);

      if (lastStatus !== status) {
        onStatusUpdate(error || null, { completed, status });
        lastStatus = status;
      }

      return { error, completed, status };
    }
  );
}

async function upload(storyId, { audioEncoding = 'aac' }, onStatusUpdate = () => {}) {
  async function updateStatus() {
    const {
      data: { error, bookId, completed, status },
    } = await getOne('stories', { id: storyId });

    onStatusUpdate(error || null, { bookId, completed, status });
    if (!(error || completed)) {
      setTimeout(updateStatus, 350);
    }
  }

  return fetch(`${BASE_URL}/stories/${storyId}/upload`, {
    method: 'POST',
    body: JSON.stringify({ audioEncoding }),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.status >= 400) {
      const { message } = await res.json();
      const error = new Error(message);
      error.status = res.status;
      console.warn(res.status, message);

      throw error;
    }

    let {
      data: { error, completed, status },
    } = await res.json();

    onStatusUpdate(error || null, { completed, status });

    setTimeout(updateStatus, 350);

    return { error, completed, status };
  });
}

async function getList(resource, params) {
  const {
    sort: { field, order },
    pagination: { page, perPage },
  } = params;
  const qp = `?orderBy=${field}&order=${order}&offset=${(page - 1) * perPage}&limit=${perPage}`;
  return fetch(`${BASE_URL}/${resource}${qp}`, getHeadersWithAuth()).then(parseResponse);
}

async function getOne(resource, params) {
  return fetch(`${BASE_URL}/${resource}/${params.id}`, getHeadersWithAuth()).then(parseResponse);
}

export async function createThernBook(data) {
  return await fetch(`${BASE_URL}/thern/books`, {
    method: 'POST',
    body: JSON.stringify(data),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.ok) {
      return res.json();
    }
    console.error(res);
    throw new Error((await res.json()).message);
  });
}

export async function getThernSearchBooks(search) {
  const data = await fetch(
    `${BASE_URL}/thern/books?search=${encodeURI(search)}`,
    getHeadersWithAuth()
  );

  return data.json();
}

export async function getThernBook(bookId) {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}`, getHeadersWithAuth());

  return data.json();
}
export async function thernBookIsInTestBookshelf(bookId) {
  const data = await fetch(
    `${BASE_URL}/thern/books/${bookId}/test-bookshelf`,
    getHeadersWithAuth()
  );
  const { value } = await data.json();

  return value;
}
export async function postThernBookInTestBookshelf(bookId, position = 'last') {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}/test-bookshelf`, {
    method: 'POST',
    body: JSON.stringify({ position }),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  });

  return data.json();
}
export async function deleteThernBookInTestBookshelf(bookId) {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}/test-bookshelf`, {
    method: 'DELETE',
    ...getHeadersWithAuth(),
  });

  return data.json();
}

export async function thernBookIsInQaBookshelf(bookId) {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}/qa-bookshelf`, getHeadersWithAuth());
  const { value } = await data.json();

  return value;
}
export async function postThernBookInQaBookshelf(bookId, position = 'last') {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}/qa-bookshelf`, {
    method: 'POST',
    body: JSON.stringify({ position }),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  });

  return data.json();
}
export async function deleteThernBookInQaBookshelf(bookId) {
  const data = await fetch(`${BASE_URL}/thern/books/${bookId}/qa-bookshelf`, {
    method: 'DELETE',
    ...getHeadersWithAuth(),
  });

  return data.json();
}

export async function getThernPublishers() {
  const data = await fetch(`${BASE_URL}/thern/publishers`, getHeadersWithAuth());

  return data.json();
}

export async function patchThernBookMeta(bookId, metaData) {
  return await fetch(`${BASE_URL}/thern/books/${bookId}`, {
    method: 'PATCH',
    body: JSON.stringify(metaData),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.ok) {
      return true;
    }
    console.error(res);
    throw new Error((await res.json()).message);
  });
}

export async function getHealth() {
  return await fetch(`${BASE_URL}/health`, {
    method: 'GET',
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.status >= 400) {
      throw new Error(`Got error from backend: ${res.status}`);
    }
    return res.json();
  });
}

export async function getTextDetails(storyId) {
  const data = await fetch(`${BASE_URL}/stories/${storyId}/text-details`, getHeadersWithAuth());

  if (data.status >= 400) {
    throw new Error((await data.json()).message);
  }

  return data.json();
}

async function getOneStatus(storyId) {
  const data = await fetch(`${BASE_URL}/stories/${storyId}/status`, getHeadersWithAuth());
  return data.json();
}

async function updatePatch(storyId, attributes) {
  return await fetch(`${BASE_URL}/stories/${storyId}`, {
    method: 'PATCH',
    body: JSON.stringify(attributes),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.ok) {
      return true;
    }
    console.error(res);
    throw new Error('Could not update story');
  });
}

async function postAction(actionName, entityId, data = null) {
  return await fetch(`${BASE_URL}/stories/${entityId}/actions/${actionName}`, {
    method: 'POST',
    body: data ? JSON.stringify(data) : undefined,
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.status >= 400) {
      throw new Error((await res.json()).message);
    }
    return res.json();
  });
}

async function updateBackgroundImage(storyId, pageId, fileId) {
  return await fetch(`${BASE_URL}/stories/${storyId}/pages/${pageId}/background-image`, {
    method: 'PUT',
    body: JSON.stringify({ fileId: fileId }),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.status >= 400) {
      throw new Error(`Got error from backend: ${res.status}`);
    }
    return res.json();
  });
}

async function updateAudio(storyId, pageId, fileId) {
  return await fetch(`${BASE_URL}/stories/${storyId}/pages/${pageId}/audio`, {
    method: 'PUT',
    body: JSON.stringify({ fileId: fileId }),
    ...getHeadersWithAuth({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    if (res.status >= 400) {
      throw new Error(`Got error from backend: ${res.status}`);
    }
    return res.json();
  });
}

async function uploadFiles(storyId, files) {
  const form = new FormData();
  files.forEach((file) => {
    if (AUDIO_MIME_TYPE_LIST.includes(file.type)) {
      form.append('audioFiles', file);
    } else if (IMAGE_MIME_TYPE_LIST.includes(file.type)) {
      form.append('imageFiles', file);
    } else if (METADATA_MIME_TYPES.json === file.type) {
      form.append('jsonFiles', file);
    } else if (METADATA_MIME_TYPES.xml === file.type) {
      form.append('xmlFiles', file);
    }
  });

  return await fetch(`${BASE_URL}/stories/${storyId}/files`, {
    method: 'POST',
    body: form,
    ...getHeadersWithAuth(),
  }).then(async (res) => {
    if (res.status >= 400) {
      throw new Error(`Got error from backend: ${res.status}`);
    }
    return res.json();
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return {
    actionAddPage: (storyId, data) => postAction('add-page', storyId, data),
    actionCutAudio: (storyId, data) => postAction('cut-audio', storyId, data),
    actionCropPages: (storyId, data) => postAction('crop', storyId, data),
    actionExclude: (storyId, data) => postAction('exclude-pages', storyId, data),
    actionOffsetAudio: (storyId, data) => postAction('offset-audio', storyId, data),
    actionSplit: (storyId, data) => postAction('split', storyId, data),
    actionSyncAudio: (storyId) => postAction('sync-audio', storyId),
    executeActionAndWait,
    getList,
    getOne,
    getOneStatus,
    getMany: catchIt('getMany', { data: [{ id: 1 }] }),
    getManyReference: catchIt('getManyReference', { data: [{ id: 1 }], total: 1 }),
    create,
    upload,
    uploadFiles,
    updateBackgroundImage,
    updateAudio,
    updatePatch,
    update: catchIt('update', { data: { id: 1 } }),
    updateMany: catchIt('updateMany', { data: [1] }),
    delete: catchIt('delete', { data: { id: 1 } }),
    deleteMany: catchIt('deleteMany', { data: [1] }),
  };
};
