import React from 'react';
import { Datagrid, DateField, List, TextField, UrlField } from 'react-admin';

const ListTranscription = (props) => {
  return (
    <div>
      <h3>Audio transcriptions...</h3>
      <List {...props} perPage={25} sort={{ field: 'id', order: 'desc' }}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="isbn" />
          <TextField source="audioFilename" />
          <TextField source="progress" />
          <DateField source="createdAt" showTime locales="sv-SE" />
          <DateField source="finishedAt" showTime locales="sv-SE" />
          <UrlField source="url" label="Link to source files" />
        </Datagrid>
      </List>
    </div>
  );
};

export default ListTranscription;
