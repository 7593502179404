import React from 'react';
import { Datagrid, List, DateField, TextField } from 'react-admin';

const ListTranscription = (props) => {

  return (
    <div>
      <h3>Audio transcriptions...</h3>
      <List {...props} perPage={25} sort={{ field: 'id', order: 'desc' }}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <DateField source="createdAt" />
        </Datagrid>
      </List>
    </div>
  );
};

export default ListTranscription;
