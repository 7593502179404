import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import { Delete } from '@mui/icons-material';

import { PLAYBACK_MODES, PLAYBACK_MODES_VALUES } from '../../constants';
import Api from '../../dataProvider/index';

const PREFIX = 'PageView';

const cls = {
  bgImage: `${PREFIX}-bgImage`,
  container: `${PREFIX}-container`,
  audio: `${PREFIX}-audio`,
};

const Root = styled('div')(({ isCover, isRight, playbackActive, playbackPaused, theme }) => ({
  outline: playbackActive
    ? `4px solid ${playbackPaused ? theme.palette.primary.light : theme.palette.red.main}`
    : null,
  outlineOffset: '-4px',
  display: 'inline-block',
  position: 'relative',
  textAlign: isCover ? 'center' : isRight ? 'left' : 'right',
  width: isCover ? '100%' : '50%',
  marginTop: '40px',
  height: 'calc(100% - 45px)',
  overflowX: 'hidden',

  [`& .${cls.audio}`]: {
    '$container:hover &': {
      transition: 'opacity 0.2s',
      opacity: 0.5,
    },
    position: 'absolute',
    height: '20px',
    width: '85%',
    left: '2.5%',
    bottom: '0',
    transition: 'opacity 0.2s',
  },
}));
const PageImage = styled('img')(({ isRight, offsetCenter }) => ({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: 'calc(100% - 40px)',
  boxShadow: '5px 5px 10px -3px #000',
  marginLeft: isRight ? -offsetCenter + 'px' : 0,
  marginRight: isRight ? 0 : -offsetCenter + 'px',
}));
const RemoveAudioIcon = styled(Delete, {
  shouldForwardProp: (prop) => prop !== 'hasAudio',
})(({ hasAudio }) => ({
  bottom: 1,
  cursor: 'pointer',
  display: hasAudio ? undefined : 'none',
  height: 20,
  opacity: 0.5,
  position: 'absolute',
  right: 10,
  width: 20,
  '&:hover': {
    opacity: 1.0,
  },
}));

const { updateAudio, updateBackgroundImage } = Api();

const PageView = React.forwardRef(
  (
    {
      isCover,
      isRight,
      page,
      backgroundImage,
      audio,
      startPlayback,
      playbackMode,
      playbackRate,
      playbackPaused,
      onPlaybackCompleted,
      id,
      ts,
      onRefresh,
      droppable,
      offsetCenter,
    },
    imageRef
  ) => {
    const hasAudio = !!(audio && audio.file);
    const playbackActive = startPlayback;

    const audioRef = useRef();
    const { url: bgUrl } = backgroundImage.file;
    const audioUrl = audio && audio.file && audio.file.url;

    const handlePause = () => {
      console.log('Paused');
      if (startPlayback && !playbackPaused) {
        onPlaybackCompleted(page);
      }
    };

    const handleAudioTimeUpdated =
      hasAudio && playbackMode === PLAYBACK_MODES.QUICK
        ? () => {
            const currentTime = audioRef.current.currentTime;
            let duration = audioRef.current.duration;

            if (duration === Infinity) {
              console.warn('Could not get audio duration info...');
              return;
            }

            if (duration > 7 && currentTime > 2.5 && currentTime < 3) {
              console.debug('Hop to end of audio', { duration, currentTime });
              audioRef.current.currentTime = duration - 3.5;
            }
          }
        : null;

    useEffect(() => {
      if (startPlayback && !playbackPaused && !hasAudio) {
        setTimeout(() => onPlaybackCompleted(page), 300);
      } else if (hasAudio && audioRef && startPlayback) {
        audioRef.current.play();
      }
    }, [startPlayback, audioRef, hasAudio, page, playbackPaused, onPlaybackCompleted]);

    useEffect(() => {
      if (audioRef?.current && playbackRate) {
        audioRef.current.playbackRate = playbackRate;
      }
    }, [audioRef, playbackRate]);

    useEffect(() => {
      // console.table({ msg: 'check pause', page, playbackPaused, startPlayback, hasAudio });
      if (!hasAudio) return;
      if (startPlayback && playbackPaused) {
        audioRef.current.pause();
      } else if (startPlayback && !playbackPaused) {
        audioRef.current.play();
      } else if (!startPlayback && !audioRef.current.paused) {
        audioRef.current.pause();
      }
    }, [playbackPaused, startPlayback, hasAudio]);

    const allowDrop = (ev) => {
      ev.preventDefault();
    };
    const drop = async (ev) => {
      ev.preventDefault();
      let file = JSON.parse(ev.dataTransfer.getData('file'));
      if (file.type === 'audio') {
        await updateAudio(id, page, file.id);
      } else if (file.type === 'image') {
        await updateBackgroundImage(id, page, file.id);
      }
      onRefresh();
    };

    const handleRemoveAudio = async () => {
      await updateAudio(id, page, null);
      onRefresh();
    };

    return (
      <Root
        {...{ isCover, isRight, playbackActive, playbackPaused }}
        onDrop={droppable ? drop : null}
        onDragOver={droppable ? allowDrop : null}
      >
        <PageImage
          {...{isRight, offsetCenter}}
          id={`page-image-${page}`}
          alt={`Page ${page}`}
          src={`${bgUrl}?ts=${ts}`}
          ref={imageRef}
        />
        {audio && audio.file && (
          <audio
            ref={audioRef}
            id={`a${page}`}
            className={cls.audio}
            controls
            onPause={handlePause}
            onTimeUpdate={handleAudioTimeUpdated}
          >
            <source id={`s${page}`} src={`${audioUrl}?ts=${ts}`} type="audio/wav" />
          </audio>
        )}
        <RemoveAudioIcon {...{ hasAudio }} onClick={handleRemoveAudio} />
      </Root>
    );
  }
);

PageView.propTypes = {
  isCover: PropTypes.bool,
  isRight: PropTypes.bool,
  page: PropTypes.number,
  audio: PropTypes.object,
  backgroundImage: PropTypes.object,

  // Playback props
  playbackActive: PropTypes.bool,
  playbackMode: PropTypes.oneOf(PLAYBACK_MODES_VALUES),
  startPlayback: PropTypes.bool,
  playbackPaused: PropTypes.bool,

  onPlaybackCompleted: PropTypes.func,
  id: PropTypes.number,
  onRefresh: PropTypes.func,
  droppable: PropTypes.bool,
  offsetCenter: PropTypes.number,
};

PageView.defaultProps = {
  isCover: false,
  isRight: false,

  playbackActive: false,
  playbackPaused: false,
  playbackMode: PLAYBACK_MODES.FULL,
  startPlayback: false,
};

export default PageView;
