import React, { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotify } from 'react-admin';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { BASE_URL } from '../../config';

import getFileSize from '../../utils/human-file-size';
import { getHeadersWithAuth, getSessionId } from '../../dataProvider/helpers';

const StyledFolder = styled('div')(() => ({
  color: 'grey',
  cursor: 'default',
  verticalAlign: 'middle',
  '& svg': {
    marginBottom: '-4px',
  },
  paddingBottom: '1px',
}));

const URL_PREFIX = `${BASE_URL}/source-files/files`;

export default function FileList({ isbn }) {
  const location = useLocation();
  const navigate = useNavigate();

  const notify = useNotify();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const forceLogin = useCallback(() => {
    notify('You must login to access this view. Redirecting...', 'warning', {}, false, 1000);

    setTimeout(() => {
      navigate(`/login`, { state: { nextPathname: location.pathname } });
    }, 1500);
  }, [navigate, location, notify]);

  useEffect(() => {
    setError(null);

    if (!getSessionId()) {
      forceLogin();
      return;
    }

    if (isbn) {
      setLoading(true);
      fetch(`${URL_PREFIX}/${isbn}`, {
        ...getHeadersWithAuth(),
      })
        .then(async (res) => {
          if (res.status === 401) {
            forceLogin();
            return;
          }
          const list = (await res.json()).map(({ name, ...rest }) => ({
            name: name.replace(`${isbn}/`, ''),
            ...rest,
          }));
          const folderList = list.filter(({ name }) => name.endsWith('/'));
          const filesList = list.filter(({ name }) => !name.endsWith('/'));
          setLoading(false);

          setFolderList(folderList);
          setFileList(filesList);
        })
        .catch((ex) => {
          setLoading(false);
          setError(ex.message);
        });
    }
  }, [isbn, forceLogin]);

  if (!isbn) return null;
  if (error) return <div style={{ color: 'red.main' }}>Error fetching files: {error}</div>;
  if (loading) return <div>Loading list for {isbn}...</div>;
  if (!fileList.length) return 'No files found';

  const handleFolderClick = () => {
    notify('Sub-folders are not supported', 'warning');
  };

  return (
    <div>
      {folderList.map(({ name }) => (
        <StyledFolder key={name} onClick={handleFolderClick}>
          <FolderOpenIcon fontSize="small" /> <span>{name}</span>
        </StyledFolder>
      ))}
      {fileList.map(({ changedAt, name, size }) => (
        <div key={name}>
          <a href={`${URL_PREFIX}/${isbn}/${name}?sessionId=${getSessionId()}`}>{name}</a>
          {', '}
          <span title={`${size} bytes`}>{getFileSize(size)}</span>
          {', '}
          <span title={changedAt}>{`${changedAt.split('T')[0]}`}</span>
        </div>
      ))}
    </div>
  );
}
