import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { getThernBook, getThernPublishers } from '../../dataProvider';
import SelectField from './SelectField';


const PREFIX = 'ThernUploadMetaData';

const cls = {
  publisher: `${PREFIX}-publisher`,
  ageGroup: `${PREFIX}-ageGroup`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`
};

const Root = styled('div')(() => ({
  [`& .${cls.publisher}`]: {
    marginTop: '5px',
    minWidth: '50%',
  },

  [`& .${cls.ageGroup}`]: {
    marginTop: '5px',
    minWidth: '50%',
  },

  [`& .${cls.left}`]: {
    width: 'calc(50% - 20px)',
    marginRight: '20px',
  },

  [`& .${cls.right}`]: {
    width: 'calc(50%)',
  }
}));

export default function ThernUploadMetaData({
  audioEncoding,
  initialMetadata,
  selectedBookId,
  style,
  onAudioEncodingChange,
  onBookLoaded,
  onMetaChange,
}) {

  const [title, setTitle] = React.useState('');
  const [isbn, setIsbn] = React.useState('');
  const [author, setAuthor] = React.useState('');
  const [illustrator, setIllustrator] = React.useState('');
  const [series, setSeries] = React.useState('');
  const [summary, setSummary] = React.useState('');
  const [publisherList, setPublisherList] = React.useState(null);
  const [publisher, setPublisher] = React.useState('');
  const [ageGroup, setAgeGroup] = React.useState('');

  const [showSettings, setShowSettings] = React.useState(false);

  const selectPublisher = (name) => {
    setPublisher(name);
    const selectedPublisher = publisherList.find((p) => p.name === name);
    if (isNaN(selectedPublisher.default_publisher_cut)) {
      console.error('Publisher cut is not a number');
      alert(
        `Publisher "${name}" is configured incorrectly (error in publisher cut).` +
          `Please contact an admin (Marcus) to resolve the problem.`
      );
      return;
    }
    onMetaChange({
      publisher: name,
      publisher_cut: selectedPublisher.default_publisher_cut,
    });
  };

  React.useEffect(() => {
    const { title, author, illustrator, publisher, ageGroup, isbn, summary } = initialMetadata;

    if (title) {
      setTitle(title);
    }
    if (isbn) {
      setIsbn(isbn);
    }
    if (author) {
      setAuthor(author);
    }
    if (illustrator) {
      setIllustrator(illustrator);
    }
    if (publisher) {
      setPublisher(publisher);
    }
    if (ageGroup) {
      setAgeGroup(ageGroup);
    }
    if (summary) {
      setSummary(summary);
    }

    onMetaChange({ title, author, illustrator, age_group: ageGroup, isbn, summary });
  }, [initialMetadata, selectedBookId, onMetaChange]);

  const getBook = useCallback(async (bookId) => {
    try {
      let book = await getThernBook(bookId);
      onBookLoaded(book);
      setTitle(book.title);
      setIsbn(book.isbn);
      setAuthor(book.author);
      setPublisher(book.publisher);
      setIllustrator(book.illustrator);
      setSeries(book.series);
      setSummary(book.summary);
      setAgeGroup(book.age_group);
    } catch (ex) {
      console.error(ex);
    }
  }, [onBookLoaded]);

  React.useEffect(() => {
    if (selectedBookId) {
      getBook(selectedBookId).then(() => {});
    }
  }, [getBook, selectedBookId]);

  React.useEffect(() => {
    getThernPublishers()
      .then((result) => setPublisherList(result))
      .catch((ex) => console.error(ex));
  }, []);

  const alphaSort = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const changeValue = (key) => {
    return ({ target: { value } }) => {
      switch (key) {
        case 'title':
          setTitle(value);
          break;
        case 'isbn':
          setIsbn(value);
          break;
        case 'author':
          setAuthor(value);
          break;
        case 'illustrator':
          setIllustrator(value);
          break;
        case 'series':
          setSeries(value);
          break;
        case 'summary':
          setSummary(value);
          break;
        case 'age_group':
          setAgeGroup(value);
          break;
        default:
          console.error('Undefined key', key);
      }
      onMetaChange({ [key]: value });
    };
  };

  return (
    <Root style={style}>
      <TextField
        autoComplete="off"
        margin="dense"
        id="book-title"
        label="Title"
        type="text"
        fullWidth
        value={title}
        onChange={changeValue('title')}
      />

      <TextField
        autoComplete="off"
        margin="dense"
        id="book-isbn"
        label="ISBN"
        type="text"
        value={isbn}
        onChange={changeValue('isbn')}
        className={cls.left}
      />

      <SelectField
        label="Publisher"
        id="book-publisher"
        value={publisher}
        onChange={(event) => selectPublisher(event.target.value)}
        className={cls.publisher}
      >
        {publisherList &&
          publisherList.sort(alphaSort).map(({ _id, name }) => (
            <MenuItem key={_id} value={name}>
              {name}
            </MenuItem>
          ))}
      </SelectField>

      <TextField
        autoComplete="off"
        margin="dense"
        id="book-author"
        label="Author"
        type="text"
        value={author}
        onChange={changeValue('author')}
        className={cls.left}
      />

      <TextField
        autoComplete="off"
        margin="dense"
        id="book-illustrator"
        label="Illustrator"
        type="text"
        fullWidth
        value={illustrator}
        onChange={changeValue('illustrator')}
        className={cls.right}
      />

      <TextField
        autoComplete="off"
        margin="dense"
        id="book-series"
        label="Series"
        type="text"
        fullWidth
        value={series}
        onChange={changeValue('series')}
        className={cls.left}
      />

      <SelectField
        label="Age group"
        id="book-age_group"
        value={ageGroup}
        onChange={changeValue('age_group')}
        className={cls.ageGroup}
      >
        <MenuItem value="0-3">0-3</MenuItem>
        <MenuItem value="3-6">3-6</MenuItem>
        <MenuItem value="6-9">6-9</MenuItem>
        <MenuItem value="9-12">9-12</MenuItem>
      </SelectField>

      <TextField
        autoComplete="off"
        margin="dense"
        id="book-summary"
        label="Summary"
        type="text"
        fullWidth
        multiline
        rows={3}
        value={summary}
        onChange={changeValue('summary')}
      />
      <center>
        <FormControlLabel
          control={
            <Switch
              checked={showSettings}
              onChange={() => setShowSettings(!showSettings)}
              name="checkedB"
              color="primary"
            />
          }
          label="Show settings"
        />
      </center>
      {showSettings && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Audio encoding</FormLabel>
          <RadioGroup
            aria-label="audio_encoding"
            name="audio_encoding1"
            row
            value={audioEncoding}
            onChange={({ target }) => onAudioEncodingChange(target.value)}
          >
            <FormControlLabel value="aac" control={<Radio />} label="AAC" />
            <FormControlLabel value="mp3" control={<Radio />} label="MP3" />
            <FormControlLabel value="wav" control={<Radio />} label="WAV" />
          </RadioGroup>
        </FormControl>
      )}
    </Root>
  );
}

ThernUploadMetaData.propTypes = {
  initialMetadata: PropTypes.object,
  selectedBookId: PropTypes.string,
  style: PropTypes.object,
  onMetaChange: PropTypes.func,
};

ThernUploadMetaData.defaultProps = {};
