import lightBlue from '@mui/material/colors/lightBlue';

const EnriqueColors = {
  black: '#1F1F1F',
  darkgray: '#373737',
  gray: '#747474',
  lightgray: '#C4C4C4',
  white: '#FFFFFF',
  blue: '#3671DF',
  red: '#EC433C',
  pink: '#E82A74',
  lightPink: '#f64087',
  backdrops: {
    red: '#5A211E',
    blue: '#3b3b6c',
    green: '#345934',
  },
};

export const defaultTheme = {
  palette: {
    white: {
      main: EnriqueColors.white,
    },
    lightgray: {
      light: EnriqueColors.white,
      main: EnriqueColors.lightgray,
      dark: EnriqueColors.gray,
      contrastText: EnriqueColors.lightgray,
    },
    gray: {
      light: EnriqueColors.lightgray,
      main: EnriqueColors.gray,
      dark: EnriqueColors.darkgray,
      contrastText: EnriqueColors.white,
    },
    dark: {
      main: EnriqueColors.black,
    },
    primary: {
      main: EnriqueColors.lightgray,
    },
    secondary: {
      light: '#5f5fc4',
      main: EnriqueColors.blue,
      dark: '#001064',
      contrastText: '#fff',
    },
    red: {
      main: EnriqueColors.red,
    },
    backdrops: EnriqueColors.backdrops,
    action: {
      main: lightBlue[200],
      active: lightBlue[200],
      hover: lightBlue[100],
      focus: lightBlue[600],
      selected: lightBlue[300],
      activeOpacity: 1,
      hoverOpacity: 0.7,
    },
    background: {
      default: '#373737',
      dark: EnriqueColors.black,
    },
    mode: 'dark',
  },
  typography: {
    allVariants: {
      color: EnriqueColors.lightgray,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${EnriqueColors.darkgray}`,
          backgroundClip: 'padding-box',
          backgroundColor: EnriqueColors.darkgray,
          backgroundImage: 'none',
          color: EnriqueColors.lightgray,
          lineHeight: 'normal',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#fff',
          color: '#4f3cc9',
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'book-action' },
          style: ({ ownerState: { color } }) => ({
            backgroundColor: EnriqueColors[color === 'secondary' ? 'blue' : 'lightgray'],
            color: EnriqueColors[color === 'secondary' ? 'white' : 'black'],
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: '7.5px',
            height: '41px',
            margin: '4px 0 4px 4px',
            padding: '2.5px 5px',
            textAlign: 'center',
            verticalAlign: 'top',
          }),
        },
        {
          props: { variant: 'execute-action' },
          style: {
            '&:hover': {
              backgroundColor: EnriqueColors.lightPink,
            },
            backgroundColor: EnriqueColors.pink,
            color: EnriqueColors.black,
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: '7.5px',
            height: '41px',
            margin: '4px 0 4px 4px',
            padding: '2.5px 5px',
            textAlign: 'center',
            verticalAlign: 'top',
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: EnriqueColors.white,
          backgroundColor: EnriqueColors.darkgray,
        },
        root: {
          border: 'none',
          borderBottom: `1px solid ${EnriqueColors.black}`,
          height: '50px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiTooltip: {
      variants: [
        {
          props: { variant: 'light' },
          styleOverrides: {
            tooltip: ({ theme }) => ({
              backgroundColor: theme.palette.common.white,
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: theme.shadows[1],
              fontSize: 11,
            }),
          },
        }
      ],
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
        },
        active: {
          borderLeft: '3px solid #4f3cc9',
        },
      },
    },
  },
};
