import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'PagePreviewSmall';

const cls = {
  pageNumber: `${PREFIX}-pageNumber`,
  percentage: `${PREFIX}-percentage`,
  user: `${PREFIX}-user`,
};

const Root = styled('div')(({ isActive }) => ({
  outlineOffset: '-4px',
  backgroundColor: isActive ? 'green' : 'gray',
  display: 'inline-block',
  fontFamily: 'Times',
  position: 'relative',
  textAlign: 'center',
  cursor: 'pointer',
  width: '140px',
  height: '40px',
  marginTop: '5px',

  [`&:hover .${cls.pageNumber}`]: {
    opacity: 0.5,
  },
  [`& .${cls.pageNumber}`]: {
    position: 'absolute',
    left: 0,
    bottom: '0%',
    top: '0%',
    width: '100%',
    verticalAlign: 'middle',
    fontSize: '35px',
    color: 'rgba(255, 255, 255, 0.85)',
    textShadow: '2px 2px 10px rgba(30, 30, 30, 0.65)',
    transition: 'opacity 0.2s',
    userSelect: 'none',
  },

  [`& .${cls.percentage}`]: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    fontSize: '15px',
    color: 'orange',
  },
  [`& .${cls.user}`]: {
    position: 'absolute',
    left: '3px',
    bottom: 0,
    fontSize: '15px',
    color: 'blue',
  },
}));

const PagePreviewSmall = ({
  isActive = false,
  pageNumber,
  similarity = 0,
  userSelection,
  onClick,
}) => {
  const ref = React.useRef(null);

  const percentage = Math.round(Math.max(0, (similarity ? similarity - 0.5 : 0) * 200)) + ' %';

  React.useEffect(() => {
    if (isActive) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isActive]);

  return (
    <Root {...{ isActive, ref, userSelection }} onClick={() => onClick(pageNumber)}>
      <div className={cls.pageNumber}>
        <span>{pageNumber || 'cover'}</span>
      </div>
      <div className={cls.user} style={{ opacity: userSelection ? 1 : 0 }}>
        user
      </div>
      <div className={cls.percentage} style={{ opacity: userSelection ? 0.5 : 1 }}>
        {percentage}
      </div>
    </Root>
  );
};

PagePreviewSmall.propTypes = {
  isActive: PropTypes.bool,
  pageNumber: PropTypes.number,
  onClick: PropTypes.func,
};

export default PagePreviewSmall;
