import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ProgressDialog = ({ open, completed, error, status, onClose }) => {
  const title = error
    ? 'Failed perform action'
    : completed
      ? 'Action completed'
      : 'Action in progress...';

  return (
    <Dialog
      {...{ open, onClose: () => {} }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'} id="alert-dialog-description">
          <div style={{ textAlign: 'center' }}>
          {error && <ErrorOutlineIcon fontSize="large" color="error" />}
          {!error && completed && <CheckCircleOutlineIcon fontSize="large" color="secondary" />}
          {!completed && <CircularProgress color="secondary" />}
          <br />
          {error || status}
          <br />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={!completed} onClick={onClose} color="primary">
          {completed ? 'Close' : 'Working...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProgressDialog.propTypes = {
  open: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.string,
  status: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ProgressDialog;
