import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useNotify, useRecordContext } from 'react-admin';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

const Root = styled('div')({
  textAlign: 'center',
});

const MetadatasField = (props) => {
  const notify = useNotify();
  const { metadata } = useRecordContext(props);
  const [headers, setHeaders] = useState(<TableRow />);
  const [rows, setRows] = useState(null);
  const [clipData, setClipData] = useState(null);
  const [canCopy, setCanCopy] = useState(false);

  useEffect(() => {
    if (!metadata) {
      setCanCopy(false);
      setClipData('');
      return;
    }
    setHeaders(
      <TableRow>
        {metadata.header.map((text) => (
          <TableCell key={text}>{text}</TableCell>
        ))}
      </TableRow>
    );

    setRows(
      metadata.rows.map(({ columns }) => (
        <TableRow key={columns[0]}>
          {columns.map((text, i) => (
            <TableCell key={text + '-' + i}>{text}</TableCell>
          ))}
        </TableRow>
      ))
    );
    const toClipboard = [
      metadata.header.join('\t'),
      ...metadata.rows.map(({ columns }) => columns.join('\t')),
    ].join('\n');
    setClipData(toClipboard);

    setCanCopy(true);
  }, [metadata]);

  const handleCopyClicked = useCallback(() => {
    notify('✂️ Copied table to clipboard');
  }, [notify]);

  return (
    <Root>
      <CopyToClipboard
        options={{ format: 'text/plain' }}
        text={clipData}
        onCopy={handleCopyClicked}
      >
        <Button disabled={!canCopy} variant="outlined" color="primary" sx={{ margin: '10px' }}>
          Copy table
        </Button>
      </CopyToClipboard>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>{headers}</TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};

MetadatasField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export default MetadatasField;
