import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { AGE_GROUPS } from '../../constants';

const EditMetadataDialog = ({ metadata, open, onClose }) => {
  const [editedMeta, setEditedMeta] = useState({});
  const [showRaw, setShowRaw] = useState(false);

  useEffect(() => {
    setEditedMeta({ ...metadata });
  }, [metadata]);

  const handleFieldChanged = (e) => {
    const { name, value } = e.target;

    setEditedMeta((m) => ({ ...m, [name]: value || '' }));
  };

  const handleClose = () => {
    setEditedMeta({ ...metadata });
    onClose();
  };

  const handleToggleShowRaw = () => {
    setShowRaw((previousValue) => !previousValue);
  };

  return (
    <Dialog
      {...{ open, onClose: handleClose }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Metadata</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText component={'span'} id="alert-dialog-description">
          <div>Show / Edit metadata for book</div>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              flexDirection: 'column',
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="title-field"
              label="Book title"
              margin="normal"
              name="title"
              InputProps={{ readOnly: true }}
              variant="outlined"
              value={editedMeta.title || ''}
              onChange={handleFieldChanged}
            />
            <TextField
              id="author-field"
              label="Author"
              margin="normal"
              name="author"
              InputProps={{ readOnly: true }}
              variant="outlined"
              value={editedMeta.author || ''}
              onChange={handleFieldChanged}
            />
            <TextField
              id="illustrator-field"
              label="Illustrator"
              margin="normal"
              name="illustrator"
              InputProps={{ readOnly: true }}
              variant="outlined"
              value={editedMeta.illustrator || ''}
              onChange={handleFieldChanged}
            />
            <TextField
              id="ageGroup-field"
              select
              label="Age group"
              margin="normal"
              name="ageGroup"
              InputProps={{ readOnly: true }}
              variant="outlined"
              value={editedMeta.ageGroup || ''}
              onChange={handleFieldChanged}
            >
              {AGE_GROUPS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '50ch' },
              flexDirection: 'column',
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="summary-field"
              label="Summary"
              InputProps={{ readOnly: true }}
              margin="normal"
              multiline
              name="summary"
              rows={4}
              variant="outlined"
              value={editedMeta.summary || ''}
              onChange={handleFieldChanged}
            />
            <Button size="small" fullWidth onClick={handleToggleShowRaw}>
              Show raw metadata
            </Button>
            {showRaw && (
              <Paper elevation={3} style={{ textAlign: 'left', fontSize: '10px', padding: '0 10px' }}>
                <pre>{JSON.stringify(metadata, null, '  ')}</pre>
              </Paper>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMetadataDialog;
