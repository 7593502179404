import React from 'react';
import {
  Create,
  FileInput,
  FileField,
  FormTab,
  RadioButtonGroupInput,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

import { MIME_TYPE_LIST } from '../../utils/mimeTypes';

const acceptedMimeTypes = MIME_TYPE_LIST.join(',');

const REGION_CHOICES = [
  { id: 'AUT,CHE,DEU', name: 'DACH' },
  { id: 'SWE', name: 'Sweden' },
];

const CreateStory = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onMutate = () => {
    notify(`resources.stories.notifications.uploadStarted`);
  };
  const onSuccess = (data) => {
    notify(`resources.stories.notifications.uploadCompleted`);
    redirect('show', 'stories', data.id, data);
  };
  const onValidate = (values) => {
    const errors = {};

    if (values.thernIsbn) {
      return {};
    }

    if (!values.regions) {
      errors.regions = 'You must choose where the book belongs';
    }
    if (!(values.files || []).length) {
      errors.files = 'At least one file is required';
    }
    return errors;
  };

  return (
    (<div>
      <Create mutationOptions={{ onMutate, onSuccess }} {...props}>
        <TabbedForm validate={onValidate}>
          <FormTab label="Upload local files">
            <TextInput fullWidth label="resources.stories.fields.title.label" source="title" />
            <RadioButtonGroupInput
              choices={REGION_CHOICES}
              label="Region"
              source="regions"
            />
            <FileInput
              accept={acceptedMimeTypes}
              multiple
              label="Files to be uploaded for book"
              source="files"
              sx={{
                '& .RaFileInput-dropZone': {
                  backgroundColor: 'backdrops.red',
                },
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </FormTab>
          <FormTab label="Load Boksnok book">
            <TextInput fullWidth label="ISBN" source="thernIsbn" helperText="The isbn number of the Boksnok (Thern) book" />
          </FormTab>
        </TabbedForm>
      </Create>
    </div>)
  );
};

export default CreateStory;
