import React, { useState } from 'react';

import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

const Search = ({ onSearch }) => {
  const [isbn, setIsbn] = useState('');

  return (
    <div>
      <form noValidate autoComplete="off">
        <TextField
          id="outlined-basic"
          label="ISBN"
          size="small"
          variant="outlined"
          value={isbn}
          onChange={({ target }) => setIsbn(target.value)}
        />

        <Button type="submit" variant="contained" color="primary" onClick={() => onSearch(isbn)}>
          Search
        </Button>
      </form>
    </div>
  );
};

export default Search;
