import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { PlayCircleFilled, PauseCircleFilled } from '@mui/icons-material';

import { PLAYBACK_MODES } from '../../constants';

const PREFIX = 'AudioControls';

const cls = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  controls: `${PREFIX}-controls`,
  quickMode: `${PREFIX}-quickMode`,
  fullMode: `${PREFIX}-fullMode`,
  playButton: `${PREFIX}-playButton`,
  icon: `${PREFIX}-icon`,
  playbackRate: `${PREFIX}-playbackRate`,
  previewContainer: `${PREFIX}-previewContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${cls.root}`]: {
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.dark.main}`,
  },

  [`& .${cls.container}`]: {
    margin: 0,
    height: '100%',
  },

  [`& .${cls.controls}`]: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '14px',
    textAlign: 'center',
    padding: '6px',
    color: '#aaa',
    lineHeight: 1.5,
    '& .smash': {
      letterSpacing: '-8px',
    },
    '& .selected': {
      fontWeight: 'bolder',
    },
  },

  [`& .${cls.playButton}`]: {
    cursor: 'pointer',
    textAlign: 'center',
    border: 'none',
    background: 'none',
    margin: '15px auto',
    display: 'block',
    padding: 0,
    '&:active': {
      '& $icon': {
        boxShadow: '1px 1px 3px 0 #000',
      },
    },
  },

  [`& .${cls.icon}`]: {
    width: 50,
    height: 50,
    color: '#ccc',
    borderRadius: 25,
    boxShadow: '3px 3px 5px 0 #000',
  },

  [`& .${cls.playbackRate}`]: {
    fontSize: 12,
    '& div': {
      margin: '0 3px',
      display: 'inline-block',
    },
    '& div.active': {
      fontWeight: 'bold',
      color: '#ddd',
    },
  },

  [`& .${cls.previewContainer}`]: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const QuickModeSpan = styled('span')(({ playbackMode }) => ({
  color: playbackMode === PLAYBACK_MODES.QUICK ? '#ddd' : '#999',
  fontWeight: playbackMode === PLAYBACK_MODES.QUICK ? 'bolder' : null,
}));
const FullModeSpan = styled('span')(({ playbackMode }) => ({
  color: playbackMode === PLAYBACK_MODES.FULL ? '#ddd' : '#999',
  fontWeight: playbackMode === PLAYBACK_MODES.FULL ? 'bolder' : null,
}));

const AudioControls = ({
  className,
  disabled,
  isPaused,
  isPlaying,
  playClicked,
  playbackMode,
  playbackPage,
  playbackRate,
  previewImageUrl,
  setPlaybackMode,
  setPlaybackRate,
  setPlaybackPage,
}) => {
  const captureKeyPress = useCallback(
    (e) => {
      if (disabled) return;
      switch (e.code) {
        case 'KeyF':
          // console.log('Fullmode activated');
          setPlaybackMode(PLAYBACK_MODES.FULL);
          localStorage.fullPlay = 'true';
          break;

        case 'KeyQ':
          // console.log('Quickmode activated');
          setPlaybackMode(PLAYBACK_MODES.QUICK);
          localStorage.removeItem('fullPlay');
          break;

        case 'KeyN':
          // console.log('Next key', { playbackPage });
          setPlaybackPage(playbackPage + 1);
          break;

        case 'KeyP':
          // console.log('Previous key', { playbackPage });
          if (playbackPage > 1) {
            setPlaybackPage(playbackPage - 1);
          }
          break;

        case 'Digit1':
          setPlaybackRate(1);
          break;
        case 'Digit2':
          setPlaybackRate(2);
          break;
        case 'Digit3':
          setPlaybackRate(3);
          break;
        case 'Digit4':
          setPlaybackRate(4);
          break;

        case 'Space':
          e.preventDefault();
          playClicked();
          break;
        default:
      }
    },
    [playClicked, playbackPage, setPlaybackMode, setPlaybackPage, setPlaybackRate, disabled]
  );
  useEffect(() => {
    document.addEventListener('keydown', captureKeyPress, false);
    return () => {
      document.removeEventListener('keydown', captureKeyPress, false);
    };
  }, [captureKeyPress]);

  return (
    <Root className={`${className} ${cls.root}`}>
      <div className={cls.container}>
        <button className={cls.playButton} onClick={playClicked}>
          <Tooltip title="Play / pause (press space)" placement="left">
            {isPlaying && !isPaused ? (
              <PauseCircleFilled className={cls.icon} />
            ) : (
              <PlayCircleFilled className={cls.icon} />
            )}
          </Tooltip>
        </button>
        <div className={cls.controls}>
          <div title="Read only the first few and last seconds of each page">
            <QuickModeSpan {...{ playbackMode }}>Quick (q)</QuickModeSpan> /{' '}
            <FullModeSpan {...{ playbackMode }} title="Read the full page">
              Full (f)
            </FullModeSpan>
          </div>
          <div>
            <span title="Skip to next page">Next (n)</span> /{' '}
            <span title="Skip to previous page">Previous (p)</span>
          </div>
          <div className={cls.playbackRate}>
            <div title="Set playback rate to 100%" className={playbackRate === 1 ? 'active' : ''}>
              100%
            </div>
            /
            <div title="Set playback rate to 200%" className={playbackRate === 2 ? 'active' : ''}>
              200%
            </div>
            /
            <div title="Set playback rate to 300%" className={playbackRate === 3 ? 'active' : ''}>
              300%
            </div>
            /
            <div title="Set playback rate to 400%" className={playbackRate === 4 ? 'active' : ''}>
              400%
            </div>
          </div>
        </div>
        {previewImageUrl && (
          <div
            className={cls.previewContainer}
            style={{ backgroundImage: `url('${previewImageUrl}')` }}
          ></div>
        )}
      </div>
    </Root>
  );
};

AudioControls.propTypes = {
  playbackMode: PropTypes.string,
  playClicked: PropTypes.func,
  isPlaying: PropTypes.bool,
  isPaused: PropTypes.bool,
  setPlaybackMode: PropTypes.func,
  setPlaybackPage: PropTypes.func,
  playbackPage: PropTypes.number,
  disabled: PropTypes.bool,
};

export default AudioControls;
