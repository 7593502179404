import { AUDIO_MIME_TYPE_LIST, IMAGE_MIME_TYPE_LIST, MIME_TYPES } from '../utils/mimeTypes';
import { HttpError } from 'react-admin';

const epubMimeType = MIME_TYPES.epub;
const jsonMimeType = MIME_TYPES.json;
const xmlMimeType = MIME_TYPES.xml;
const pdfMimeType = MIME_TYPES.pdf;
const wavMimeType = MIME_TYPES.wav;

export async function parseResponse(res) {
  const { status } = res;
  const obj = await res.json();

  if (status >= 400) {
    throw new HttpError(obj.message, status, obj);
  }
  return obj;
}

export const catchIt = (name, resultData) => {
  return async (resource, params) => {
    console.log(`From ${name}:`, { resource, params });
    return new Promise((res) => res(resultData));
  };
};

export const extractAudioFilesToUpload = (files) => {
  const filesByType = files.reduce(
    (res, { rawFile }) => ({
      ...res,
      [rawFile.type]: [...(res[rawFile.type] || []), rawFile],
    }),
    {}
  );

  if ((filesByType[wavMimeType] || []).length !== 1) {
    throw new Error('There can only be one wav file');
  }

  const wavFile = filesByType[wavMimeType][0];

  return { wavFile };
};
export const extractFilesToUpload = (files) => {
  const filesByType = files.reduce(
    (res, { rawFile }) => ({
      ...res,
      [rawFile.type]: [...(res[rawFile.type] || []), rawFile],
    }),
    {}
  );
  const epubCount = (filesByType[epubMimeType] || []).length;
  const pdfCount = (filesByType[pdfMimeType] || []).length;
  const imageCount = Object.keys(filesByType).filter((key) => key.startsWith('image/')).length;
  const xmlCount = (filesByType[xmlMimeType] || []).length;

  if (epubCount > 1) {
    throw new Error("There can't be more than ONE EPUB file");
  } else if (epubCount < 1 && pdfCount < 1 && imageCount < 1) {
    throw new Error('There must be either a PDF or at least one image file');
  } else if (epubCount > 0 && pdfCount > 0) {
    throw new Error("There can't be both an epub and pdf file");
  } else if (xmlCount > 1) {
    throw new Error("There can't be more than one XML file");
  }

  // Check identical file names
  [...files].sort((a, b) => {
    if (a.rawFile.name === b.rawFile.name)
      throw new Error(`Remove multiple files having same name: ${a.rawFile.name}`);
    return 0;
  });

  const pdfs = filesByType[pdfMimeType] || [];
  const xmlFiles = filesByType[xmlMimeType] || [];
  const [epub] = filesByType[epubMimeType] || [];
  const audioFiles = AUDIO_MIME_TYPE_LIST.map((mimeType) => filesByType[mimeType] || [])
    .flat()
    // Todo: Fix sort order (do a sort on last number in file name, OR this doesn't matter for backend...
    .sort((a, b) => a.name.localeCompare(b.name));

  const imageFiles = IMAGE_MIME_TYPE_LIST.map((mimeType) => filesByType[mimeType] || [])
    .flat()
    .sort((a, b) => a.name.localeCompare(b.name));

  const jsonFiles = filesByType[jsonMimeType] || [];

  return { epub, pdfs, audioFiles, imageFiles, jsonFiles, xmlFiles };
};

export const getSessionId = () => localStorage.getItem('sessionId');
export const getHeadersWithAuth = (headers = {}) => {
  return {
    headers: {
      ...headers,
      Authorization: `session ${getSessionId()}`,
    },
  };
};
