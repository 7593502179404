import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecordContext } from 'react-admin';

import { styled } from '@mui/material/styles';

import { matchPageTextsToTranscription } from '../../../../utils/Text2';
import { getTextDetails } from '../../../../dataProvider/index';

import Pages from './Pages';
import PageView from './PageView';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.gray.dark,
  height: '100%',
  position: 'relative',
}));
const StyledPages = styled(Pages)({
  background: '#333',
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  overflowY: 'scroll',
  overflowX: 'hidden',
  width: '150px',
});
const StyledPageView = styled(PageView)({
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  position: 'absolute',
  left: '150px',
  top: 0,
  bottom: 0,
  right: 0,
});

const l = console.log;

function calculateAudioEditParams(soundObject, pageMatches, userSelections) {
  try {
    if (!soundObject || !pageMatches || !userSelections) return [];
    let results = pageMatches
      .filter((x) => x && x.pageNo >= 0 && x.start !== null && x.end !== null)
      .reduce((res, { pageNo, start, end }) => ({ ...res, [pageNo]: { pageNo, start, end } }), {});

    results = Object.entries(userSelections).reduce((res, [pageNo, [startIndex, endIndex]]) => {
      if (
        startIndex >= 0 &&
        endIndex >= startIndex &&
        soundObject.words[startIndex] &&
        soundObject.words[endIndex]
      ) {
        const { start } = soundObject.words[startIndex];
        const { end } = soundObject.words[endIndex];

        res[pageNo] = { pageNo, start, end };
      } else {
        delete res[pageNo];
      }
      return res;
    }, results);

    return Object.values(results);
  } catch (e) {
    l(e);
    return [
      {
        pageNo: 'error, command to compute cli command failed',
      },
    ];
  }
}

const Base = (props) => {
  const { open, onCutParameterChange } = props;
  const { files, id: storyId, pageCount, pages: modelPages } = useRecordContext(props);
  const [soundObject, setSoundObject] = useState({
    words: [
      { word: 'Not', start: 0, end: 1, dur: 1 },
      { word: 'yet', start: 1, end: 2, dur: 1 },
      { word: 'loaded', start: 2, end: 3, dur: 1 },
    ],
  });
  const [textObject, setTextObject] = useState(
    [...new Array(100)].map((_, pageNo) => ({ text: '', pageNo }))
  );
  const [activePage, setActivePage] = useState(0);
  const [userSelected, setUserSelected] = useState({});
  const [pageOffsets, setPageOffsets] = useState({});
  const { audioFileUrl, audioFileSuffix } = useMemo(() => {
    const audioFiles = (files || [])
      .filter(({ type }) => type === 'audio')
      .sort((a, b) => b.size - a.size);

    if (soundObject && soundObject.source && soundObject.source.size) {
      const { size } = soundObject.source;
      const file = audioFiles.filter((file) => file.size === size)[0];

      if (file) {
        return {
          audioFileUrl: file.url,
          audioFileSuffix: file.meta.originalName.split('.').slice(-1)[0],
        };
      }
    }

    if (audioFiles.length) {
      return {
        audioFileUrl: audioFiles[0].url,
        audioFileSuffix: audioFiles[0].meta.originalName.split('.').slice(-1)[0],
      };
    }

    return {};
  }, [files, soundObject]);

  function handleStartOffset(e, value) {
    const end = (pageOffsets[activePage] || [null, null])[1];
    setPageOffsets((pO) => ({ ...pO, [activePage]: [value, end] }));
  }

  function handleEndOffset(e, value) {
    const start = (pageOffsets[activePage] || [null, null])[0];
    setPageOffsets((pO) => ({ ...pO, [activePage]: [start, value] }));
  }

  const images = useMemo(() => modelPages.map((p) => p.backgroundImage.file.url), [modelPages]);
  const pages = images;

  useEffect(() => {
    getTextDetails(storyId)
      .then(({ pageTexts, soundObject }) => {
        setTextObject(pageTexts);
        setSoundObject(soundObject);
      })
      .catch((ex) => {
        alert('Error loading audio cutting details - ' + ex.message);
      });
  }, [storyId]);

  const pageMatches = useMemo(() => {
    const pageTexts = textObject.map(({ text }) => text);
    const transcription = soundObject.words;
    return matchPageTextsToTranscription(pageTexts, transcription).map((i) =>
      i.startIndex !== null && i.endIndex !== null ? i : null
    );
  }, [soundObject, textObject]);

  const us = userSelected[activePage];
  const selStart = us ? us[0] : pageMatches[activePage] ? pageMatches[activePage].startIndex : null;
  const selEnd = us ? us[1] : pageMatches[activePage] ? pageMatches[activePage].endIndex : null;
  const transcribedText =
    selEnd && selStart
      ? soundObject.words
          .filter((_, i) => i >= selStart && i <= selEnd)
          .map((x) => x.word)
          .join(' ')
      : '';

  const handleSelectedWords = useCallback(
    (page, index, value) => {
      const updated = { ...userSelected };
      if (index === null) {
        updated[page] = [null, null];
      } else {
        updated[page] = [selStart, selEnd];
        updated[page][index] = value;
      }
      setUserSelected(updated);
    },
    [selEnd, selStart, userSelected]
  );

  const handleSetActivePage = useCallback(
    (page) => {
      if (page < 0 || page === pageCount) return;

      setActivePage(page);
    },
    [pageCount]
  );

  const handleSetNextAfterLast = useCallback(() => {
    const page = activePage + 1;
    if (page === pageCount) return;
    try {
      const currentUS = userSelected[activePage] || [];
      const nextUS = userSelected[page] || [];
      const last = currentUS[1] || pageMatches[activePage]?.endIndex;

      if (last) {
        const updated = { ...userSelected };
        const end = nextUS[1] || pageMatches[page]?.endIndex || currentUS[1] + 4;
        updated[page] = [last + 1, end];
        setUserSelected(updated);
      }
      setActivePage(page);
    } catch (_) {
      console.log(_);
    }
  }, [activePage, pageCount, pageMatches, setActivePage, setUserSelected, userSelected]);

  useEffect(() => {
    const result = calculateAudioEditParams(soundObject, pageMatches, userSelected);
    const converted = result.map(({ pageNo, start, end }) => {
      const [startOffset, endOffset] = pageOffsets[pageNo] || [0, 0];
      return {
        page: +pageNo,
        from: Math.max(0, +start + startOffset),
        to: Math.max(0, +end + endOffset),
      };
    });

    onCutParameterChange(converted);
  }, [soundObject, pageOffsets, pageMatches, userSelected, onCutParameterChange]);

  return (
    <Root>
      <StyledPages
        {...{ pages, activePage, pageMatches, userSelected }}
        onPageClick={(x) => handleSetActivePage(x)}
      />
      <StyledPageView
        activateKeyShortcuts={open}
        audioUrl={audioFileUrl}
        audioSuffix={audioFileSuffix}
        id={storyId}
        image={images[activePage]}
        pageCount={modelPages.length}
        pageMatch={pageMatches[activePage]}
        pageMatches={pageMatches}
        pageNo={activePage}
        pageOffsets={pageOffsets[activePage] || [0, 0]}
        userSelectedWords={userSelected[activePage]}
        wordList={soundObject}
        selStart={selStart}
        selEnd={selEnd}
        transcribedText={transcribedText}
        onSelectedWords={handleSelectedWords}
        onSetActivePage={handleSetActivePage}
        onSetNextAfterLast={handleSetNextAfterLast}
        onStartOffsetChange={handleStartOffset}
        onEndOffsetChange={handleEndOffset}
      />
    </Root>
  );
};

Base.propTypes = {
  onCutParameterChange: PropTypes.func.isRequired,
};

export default Base;
