import React, { useState } from 'react';

import { useNotify } from 'react-admin';

import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import Api from '../../dataProvider/index';

const ICON_STYLE = {
  verticalAlign: 'baseline',
};

const OffsetNumber = styled('span')({
  fontSize: '56px',
  margin: '18px',
});

const { actionOffsetAudio } = Api();

const AddPageDialog = ({ activePage, open, onClose, id }) => {
  const notify = useNotify();
  const [offsetNumber, setOffsetNumber] = useState(-1);
  const [allPages, setAllPages] = useState(true);

  async function send() {
    const offset = Number(offsetNumber);
    const options = {
      offset,
      ...(allPages ? {} : { fromPage: activePage }),
    };

    actionOffsetAudio(id, options)
      .then(() => {
        onClose(null, true);
      })
      .catch((ex) => {
        onClose(null, true);
        notify(`Error: ${ex.message}`, 'warning');
      });
  }

  function decOffset() {
    setOffsetNumber(offsetNumber - 1);
  }
  function incOffset() {
    setOffsetNumber(offsetNumber + 1);
  }

  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Offset audio</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText component={'span'} id="alert-dialog-description">
          <div>Enter how many pages you want to move the audio files.</div>
          <div>
            <IconButton size="large" sx={ICON_STYLE} onClick={decOffset}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <OffsetNumber>{offsetNumber}</OffsetNumber>
            <IconButton size="large" sx={ICON_STYLE} onClick={incOffset}>
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={allPages}
                  onChange={() => setAllPages(!allPages)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Offset all audio"
            />
          </div>
          <div>Offset from current page: {activePage}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" size="small" variant="contained" onClick={send}>
          Offset
        </Button>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPageDialog;
