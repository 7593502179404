import { BASE_URL } from '../config';
const { getSessionId, parseResponse } = require('./helpers');

export default function Slacker (action, data) {
  const username = localStorage.getItem('username');
  const info = JSON.stringify({
    username,
    action,
    ...data,
    timestamp: new Date(),
  });
  fetch(`${BASE_URL}/slack/measurements`, {
    method: 'POST',
    body: JSON.stringify({ message: info }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `session ${getSessionId()}`,
    },
  })
    .then(parseResponse)
    .then((json) => {
      if (json.message !== 'ok') {
        console.error('slacker', json);
      }
    });
};
