function calcBookDiff(withAudio, withoutAudio, lastAudio, lastWithout) {
  const total = withAudio + withoutAudio;
  const lastTotal = lastAudio + lastWithout;
  const dAudio = withAudio - lastAudio;
  const dTotal = total - lastTotal;
  const percent = Math.round((100 * withAudio) / total);

  return `${withAudio}/${total} (${percent}%) ±[${dAudio}/${dTotal}]`;
}

export const getWeeksDiff = (weekNo, numbersData, sweExtraRows, dachExtraRows) => {
  localStorage.setItem(`book-stats-week-${weekNo}`, JSON.stringify(numbersData));
  const lastWeek = localStorage.getItem(`book-stats-week-${weekNo - 1}`);

  if (!lastWeek) {
    return null;
  }
  const lastWeeksNumbers = JSON.parse(lastWeek);
  const regions = Object.keys(lastWeeksNumbers);
  const extraRows = {
    swe: sweExtraRows || [],
    dach: dachExtraRows || [],
  }

  return regions.reduce((res, region) => {
    const totalNumber = [0, 0, 0, 0];
    return {
      ...res,
      [region]: [
        ...numbersData[region].map(([age, withAudio, withoutAudio], index) => {
          const last = lastWeeksNumbers[region][index];
          totalNumber[0] += withAudio;
          totalNumber[1] += withoutAudio;
          totalNumber[2] += last[1];
          totalNumber[3] += last[2];
          return calcBookDiff(withAudio, withoutAudio, last[1], last[2]);
        }),
        calcBookDiff(...totalNumber),
        ...extraRows[region],
      ].join('\n'),
    };
  }, {});
};
