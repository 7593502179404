import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import IconButton from '../common/IconButton';

const Root = styled('div')(({ selectable }) => ({
  background: '#454545',
  overflow: 'hidden',
  height: selectable ? 50 : 0,
  transition: 'height 0.25s ease-out',
  '& button': {
    height: 50,
    margin: 0,
    padding: '10px 0',
    width: '33.3%',
  },
}));

const SelectionControls = ({ className, selectedPages, selectable, onUpdate }) => {
  const handleAll = useCallback(() => {
    onUpdate(selectedPages.map(() => true));
  }, [selectedPages, onUpdate]);
  const handleNone = useCallback(() => {
    onUpdate(selectedPages.map(() => false));
  }, [selectedPages, onUpdate]);
  const handleInvert = useCallback(() => {
    onUpdate(selectedPages.map((value) => !value));
  }, [selectedPages, onUpdate]);

  return (
    <Root {...{ selectable }} className={className}>
      <IconButton icon={<CheckCircleIcon />} outlined={false} onClick={handleAll} size="large">
        Select all
      </IconButton>
      <IconButton
        icon={<RadioButtonUncheckedIcon />}
        outlined={false}
        onClick={handleNone}
        size="large"
      >
        Select none
      </IconButton>
      <IconButton
        icon={<RadioButtonCheckedIcon />}
        outlined={false}
        onClick={handleInvert}
        size="large"
      >
        Invert selection
      </IconButton>
    </Root>
  );
};

SelectionControls.propTypes = {
  pages: PropTypes.array,
  selectedPages: PropTypes.array,
  selectable: PropTypes.bool,
  handlePageClick: PropTypes.func,
  handlePageSelect: PropTypes.func,
  activePages: PropTypes.array,
};

export default SelectionControls;
