import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';

const ListStories = (props) => {
  return (
    <div>
      <h2 style={{ fontFamily: 'Karla, open-sans', color: '#888'}}>Story builds</h2>
      <List {...props} perPage={25} sort={{ field: 'id', order: 'desc' }}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="title" />
          <BooleanField source="hasAudio" />
          <BooleanField source="completed" />
          <TextField source="sourceType" />
        </Datagrid>
      </List>
    </div>
  );
};

export default ListStories;
