import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import List from './List';
import Create from './Create';
import Show from './Show';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    list: List,
    create: Create,
    show: Show,
    Icon: PictureAsPdfIcon,
};
