import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import {
  GetApp,
  PictureAsPdf,
  Image,
  Audiotrack,
  PlayCircleFilled,
  PauseCircleFilled,
} from '@mui/icons-material';

const PREFIX = 'File';

const cls = {
  file: `${PREFIX}-file`,
  audioIcon: `${PREFIX}-audioIcon`,
  playIcon: `${PREFIX}-playIcon`,
  pauseIcon: `${PREFIX}-pauseIcon`,
  icon: `${PREFIX}-icon`,
  downloadIcon: `${PREFIX}-downloadIcon`,
  iconText: `${PREFIX}-iconText`,
  preview: `${PREFIX}-preview`,
  previewMeta: `${PREFIX}-previewMeta`,
};

const Root = styled('div')(({ theme, isPlaying }) => ({
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: '10px',
  '&:hover': {
    background: 'rgba(255,255,255,0.3)',
  },

  [`&:hover .${cls.audioIcon}`]: {
    display: 'none',
  },
  [`& .${cls.audioIcon}`]: {
    width: '20px',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    display: isPlaying ? 'none' : undefined,
  },
  [`&:hover .${cls.playIcon}`]: {
    display: isPlaying ? 'none' : 'inline-block',
  },
  [`& .${cls.playIcon}`]: {
    width: '20px',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    display: 'none',
  },
  [`& .${cls.pauseIcon}`]: {
    display: isPlaying ? undefined : 'none',
    width: '20px',
    verticalAlign: 'middle',
  },

  [`& .${cls.icon}`]: {
    width: '20px',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
  },

  [`& .${cls.downloadIcon}`]: {
    cursor: 'pointer',
    marginLeft: '4px',
    width: '20px',
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
  },

  [`& .${cls.iconText}`]: {
    verticalAlign: 'baseline',
    marginLeft: '5px',
    fontFamily: 'sans-serif',
    cursor: 'default',
    userSelect: 'none',
  },

  [`& .${cls.preview}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 100,
    display: 'none',
  },

  [`& .${cls.previewMeta}`]: {
    position: 'absolute',
    color: 'white',
    fontSize: '8px',
    right: 105,
    top: 20,
    display: 'none',
  },
}));

const File = ({ file, onUpdatePreviewUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const [audio] = useState(new Audio());

  const handleFileClick = () => {
    if (file.type === 'audio') {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.src = file.url;
        audio.currentTime = 0;
        audio.onpause = () => {
          setIsPlaying(false);
        };
        audio.play();
        setIsPlaying(true);
      }
    }
  };

  const handleMouseOver = () => {
    if (file.type === 'image') {
      onUpdatePreviewUrl(file.url);
    }
  };

  const handleMouseOut = () => {
    if (file.type === 'image') {
      onUpdatePreviewUrl(null);
    }
  };

  const drag = (ev) => {
    console.log('Start drag');
    ev.dataTransfer.setData('file', JSON.stringify(file));
  };

  // const metadata = file.type === 'image' ? `${file.meta.width}x${file.meta.height}` : '';
  const filename = file.meta.originalName ? file.meta.originalName : file.id;
  const downloadable = (file.url || '').startsWith('http');

  return (
    <Root {...{ isPlaying }} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver}>
      <div draggable={file.type !== 'pdf'} onDragStart={drag}>
        {file.type === 'pdf' && <PictureAsPdf className={cls.icon} />}
        {file.type === 'image' && <Image className={cls.icon} />}
        {file.type === 'audio' && (
          <>
            <Audiotrack className={cls.audioIcon} onClick={handleFileClick} />
            <PlayCircleFilled className={cls.playIcon} onClick={handleFileClick} />
            <PauseCircleFilled className={cls.pauseIcon} onClick={handleFileClick} />
          </>
        )}
        <span className={cls.iconText} onClick={handleFileClick}>
          {filename}
        </span>
        {downloadable && (
          <a className="download" href={`${file.url}?download=true`}>
            <GetApp className={cls.downloadIcon} titleAccess={`Download file ${filename}`} />
          </a>
        )}
      </div>
    </Root>
  );
};

File.propTypes = {
  file: PropTypes.object,
};

File.defaultProps = {
  onUpdatePreviewUrl: () => {},
};

export default File;
