import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import AssetsList from './AssetsList';
import AudioControls from './AudioControls';
import PagesOverview from './PagesOverview';
import SelectionControls from './SelectionControls';
import Spread from './Spread';
import Toolbar from './Toolbar';


const Sizes = {
  Header: { height: 50 },
  AudioControls: { height: 200 },
  LeftSide: { width: 256, padding: 10 },
  RightSide: { width: 256 },
  Toolbar: { height: 60 },
};
const b = 0;

const StyledMainView = styled('div')({
  fontSize: 0,
  position: 'relative',
  height: 'calc(100vh - 55px)',
  boxShadow: `inset 0px 0px 0px ${b}px red`,
});

const StyledAssetsList = styled(AssetsList)({
  position: 'absolute',
  width: Sizes.RightSide.width,
  right: 0,
  top: Sizes.Toolbar.height,
  bottom: Sizes.AudioControls.height,
  boxShadow: `inset 0px 0px 0px ${b}px blue`,
});
const StyledAudioControls = styled(AudioControls)({
  position: 'absolute',
  height: Sizes.AudioControls.height,
  width: Sizes.RightSide.width,
  right: 0,
  bottom: 0,
  boxShadow: `inset 0px 0px 0px ${b}px magenta`,
});
const StyledPagesOverview = styled(PagesOverview)({
  position: 'absolute',
  bottom: 0,
  width: `calc(${Sizes.LeftSide.width}px - 2 * ${Sizes.LeftSide.padding}px)`,
  left: 0,
  top: Sizes.Toolbar.height,
  padding: Sizes.LeftSide.padding,
  boxShadow: `inset 0px 0px 0px ${b}px yellow`,
});
const StyledSelectionControls = styled(SelectionControls)({
  position: 'absolute',
  width: Sizes.LeftSide.width + 1,
  left: 0,
  top: Sizes.Toolbar.height + Sizes.LeftSide.padding - 1,
  padding: 0,
});
const StyledSpread = styled(Spread)({
  position: 'absolute',
  left: Sizes.LeftSide.width + 1,
  right: Sizes.RightSide.width + 1,
  top: Sizes.Toolbar.height,
  bottom: 0,
  boxShadow: `inset 0px 0px 0px ${b}px green`,
});
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  position: 'absolute',
  height: Sizes.Toolbar.height,
  width: '100%',
  left: 0,
  top: 0,
  borderBottom: `1px solid ${theme.palette.dark.main}`,
  boxShadow: `inset 0px 0px 0px ${b}px cyan`,
}));

const MainView = (props) => {
  const {
    changeMode,
    cropState,
    disableAudioControls,
    files,
    handlePlaybackCompleted,
    id,
    isPaused,
    isPlaying,
    leftPageNumber,
    mode,
    pages,
    playbackMode,
    playbackPage,
    playbackRate,
    playClicked,
    refresh,
    rightPageNumber,
    selectable,
    selectedPages,
    storyTitle,
    setCropState,
    setDisableAudioControls,
    setPlaybackMode,
    setPlaybackRate,
    setPlayPage,
    setShowAddPageDialog,
    setShowAudioSilenceDialog,
    setShowMetadataDialog,
    setShowOffsetAudioDialog,
    sourceType,
    ts,

    onPageClick,
    onPageSelect,
    onUpdateSelectedPages,
  } = props;

  const [previewUrl, setPreviewUrl] = useState(null);

  return (
    <StyledMainView>
      <StyledPagesOverview
        activePages={[leftPageNumber, rightPageNumber]}
        pages={pages}
        selectable={selectable}
        selectedPages={selectedPages}
        ts={ts}
        onPageClick={onPageClick}
        onPageSelect={onPageSelect}
      />

      <StyledSelectionControls
        selectable={selectable}
        selectedPages={selectedPages}
        onUpdate={onUpdateSelectedPages}
      />

      <StyledAssetsList
        files={files}
        onRefresh={refresh}
        onUpdatePreviewUrl={setPreviewUrl}
      />

      <StyledAudioControls
        previewImageUrl={previewUrl}
        playbackMode={playbackMode}
        playbackPage={playbackPage}
        playbackRate={playbackRate}
        playClicked={playClicked}
        isPlaying={isPlaying}
        isPaused={isPaused}
        disabled={disableAudioControls}
        setPlaybackMode={setPlaybackMode}
        setPlaybackRate={setPlaybackRate}
        setPlaybackPage={setPlayPage}
      />

      <StyledSpread
        {...{ pages, leftPageNumber, rightPageNumber }}
        id={id}
        isPaused={isPaused}
        isPlaying={isPlaying}
        mode={mode}
        playbackMode={playbackMode}
        playbackPage={playbackPage}
        playbackRate={playbackRate}
        setCropState={setCropState}
        handlePlaybackCompleted={handlePlaybackCompleted}
        ts={ts}
        onRefresh={refresh}
      />
      <StyledToolbar
        cropState={cropState}
        id={id}
        mode={mode}
        selectedPages={selectedPages}
        sourceType={sourceType}
        storyTitle={storyTitle}
        onDisableShortcuts={setDisableAudioControls}
        onModeChanged={changeMode}
        onRefresh={refresh}
        onShowAddPageDialogChanged={setShowAddPageDialog}
        onShowAudioSilenceChanged={setShowAudioSilenceDialog}
        onShowMetadataDialogChanged={setShowMetadataDialog}
        onShowOffsetAudioDialogChanged={setShowOffsetAudioDialog}
      />
    </StyledMainView>
  );
};

export default MainView;
