export const AUDIO_MIME_TYPES = Object.freeze({
  aac: 'audio/aac',
  m4a: 'audio/x-m4a',
  mp3: 'audio/mpeg',
  mp4: 'audio/mp4',
  wav: 'audio/wav',
});
export const AUDIO_MIME_TYPE_LIST = [...new Set(Object.values(AUDIO_MIME_TYPES))];

export const DOCUMENT_MIME_TYPES = Object.freeze({
  epub: 'application/epub+zip',
  pdf: 'application/pdf',
});
export const DOCUMENT_MIME_TYPE_LIST = [...new Set(Object.values(DOCUMENT_MIME_TYPES))];

export const IMAGE_MIME_TYPES = Object.freeze({
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
});
export const IMAGE_MIME_TYPE_LIST = [...new Set(Object.values(IMAGE_MIME_TYPES))];

export const METADATA_MIME_TYPES = Object.freeze({
  json: 'application/json',
  xml: 'text/xml',
});
export const METADATA_MIME_TYPE_LIST = [...new Set(Object.values(IMAGE_MIME_TYPES))];

export const MIME_TYPES = Object.freeze({
  ...AUDIO_MIME_TYPES,
  ...DOCUMENT_MIME_TYPES,
  ...IMAGE_MIME_TYPES,
  ...METADATA_MIME_TYPES,
});
export const MIME_TYPE_LIST = [...new Set(Object.values(MIME_TYPES))];
