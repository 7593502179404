import React from 'react';

import { styled } from '@mui/material/styles';
import { Show, SimpleShowLayout } from 'react-admin';

import BookTitle from '../../components/fields/BookTitle';
import BookShowField from '../../components/fields/BookShowField';

const StyledShow = styled(Show)({
  marginTop: '-11px',
  marginRight: '-32px',
  marginLeft: '-14px',
  marginBottom: '-1px',
  '& .RaShow-main': {
    // margin: 0,
  },
  '& .RaShow-card': {
    borderRadius: 0,
    padding: 0,
  },
});
// const Show = styled(Show)({
//   '& .sRaShow-card': {
//     borderRadius: 0,
//     padding: 0,
//   },
//   [`& .${classes.root}`]: {},
//   [`& .${classes.noActions}`]: {
//     margin: 0,
//   },
// });

const ShowStory = (props) => {
  return (
    <StyledShow
      {...props}
      title={<BookTitle />}
      actions={false}
      // sx={SHOW_STYLES}
    >
      <SimpleShowLayout sx={{ padding: '0 !important', height: 'calc(100vh - 55px)' }}>
        <BookShowField />
      </SimpleShowLayout>
    </StyledShow>
  );
};

export default ShowStory;
