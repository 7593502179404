import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SubTitle = styled('h3')({
  fontFamily: 'Karla, open-sans',
  textAlign: 'center',
});

export default function ThernUploadStatus({
  bookId,
  completed,
  error,
  inQaBookshelf,
  inTestBookshelf,
  status,
  qaShelfChanging,
  qaShelfChanged,
  testShelfChanging,
  testShelfChanged,
  onAddToQaBookshelf,
  onAddToTestBookshelf,
  onRemoveFromQaBookshelf,
  onRemoveFromTestBookshelf,
}) {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      {error && <ErrorOutlineIcon fontSize="large" color="error" />}
      {!error && completed && <CheckCircleOutlineIcon fontSize="large" color="secondary" />}
      {!completed && <CircularProgress color="secondary" />}
      <br />
      {error || (status && status.replace('Thern', 'Boksnok'))}
      <br />
      {!error && completed && (
        <a
          href={`https://thern.boksnok.se/index.html#book/${bookId}`}
          target="_blank"
          rel="noreferrer"
        >
          Open in Boksnok Admin
        </a>
      )}
      <br />
      <br />
      <br />
      <SubTitle>QA bookshelf</SubTitle>
      <div>
        {inQaBookshelf === false && (
          <Button
            color="primary"
            disabled={qaShelfChanging || qaShelfChanged}
            size="small"
            variant="outlined"
            onClick={onAddToQaBookshelf}
          >
            {qaShelfChanged ? '✅ Added' : qaShelfChanging ? 'Adding ...' : 'Add to'}
          </Button>
        )}
        {inQaBookshelf === true && (
          <Button
            color="secondary"
            disabled={qaShelfChanging || qaShelfChanged}
            size="small"
            variant="outlined"
            onClick={onRemoveFromQaBookshelf}
          >
            {qaShelfChanged ? '✅ Removed' : qaShelfChanging ? 'Removing ...' : 'Remove from'}
          </Button>
        )}
      </div>
      <SubTitle>Test bookshelf</SubTitle>
      <div>
        {inTestBookshelf === false && (
          <Button
            color="primary"
            disabled={testShelfChanging || testShelfChanged}
            size="small"
            variant="outlined"
            onClick={onAddToTestBookshelf}
          >
            {testShelfChanged ? '✅ Added' : testShelfChanging ? 'Adding ...' : 'Add to'}
          </Button>
        )}
        {inTestBookshelf === true && (
          <Button
            color="secondary"
            disabled={testShelfChanging || testShelfChanged}
            size="small"
            variant="outlined"
            onClick={onRemoveFromTestBookshelf}
          >
            {testShelfChanged ? '✅ Removed' : testShelfChanging ? 'Removing ...' : 'Remove from'}
          </Button>
        )}
      </div>
    </div>
  );
}

ThernUploadStatus.propTypes = {
  bookId: PropTypes.string,
  completed: PropTypes.bool,
  error: PropTypes.string,
  inQaBookshelf: PropTypes.bool,
  inTestBookshelf: PropTypes.bool,
  status: PropTypes.string,
  qaShelfChanging: PropTypes.bool,
  qaShelfChanged: PropTypes.bool,
  testShelfChanging: PropTypes.bool,
  testShelfChanged: PropTypes.bool,
  onAddToQaBookshelf: PropTypes.func.isRequired,
  onAddToTestBookshelf: PropTypes.func.isRequired,
  onRemoveFromQaBookshelf: PropTypes.func.isRequired,
  onRemoveFromTestBookshelf: PropTypes.func.isRequired,
};
